const Colors = {
    paolaVeroneseGreen: "var(--paolaVeroneseGreen)",
    marigold: "var(--marigold)",
    sheenGreen: "var(--sheenGreen)",
    white: 'var(--white)',
    degradado: "var(--degradado",
    orangeRedCrayola: "var(--orangeRedCrayola)",
    titaniumYellow: "var(--titaniumYellow)",
    goldFusion: "var(--goldFusion)",
    artichoke: "var(--artichoke)",
    dimGray: "var(--dimGray)",
    quickSilver: "var(--quickSilver)",
    mantis: "var(--mantis)",
    maximumBlue: "var(--maximumBlue)",
    tenneTawny: "var(--tenneTawny)",
    jet: "var(--jet)",
    grayWeb: "var(--grayWeb)",
    grayWebOpacity: "var(--grayWebOpacity)",
    spanishGray: "var(--spanishGray)",
    gainsboro: "var(--gainsboro)",
    erieBlack: "var(--erieBlack)"
};
export default Colors;
