import React from 'react';

import styles from "./styles";
import colors from "../../../theme/colors";

const DafoOption = ({name, id, onOptionSelected, isOptionSelected, maximumReached}) => {

    return (
        <div
            style={
                isOptionSelected
                    ? styles.contentSelected
                    : !isOptionSelected && maximumReached
                        ? styles.contentDisabled
                        : styles.content
            }
            className="dafo-option"
            onClick={() => onOptionSelected(id)}
        >
            <p style={{
                ...styles.name,
                color: isOptionSelected
                    ? colors.marigold
                    : !isOptionSelected && maximumReached
                        ? colors.spanishGray
                        : colors.sheenGreen
            }}>
                {name}
            </p>
        </div>
    )
};

export default DafoOption;
