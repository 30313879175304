import React from 'react';

import styles from './styles';
import {useHistory} from "react-router-dom";
import Body from "../../../components/composed/Body/Body";
import logo from "../../../assets/logo.png";
import Button from "../../../components/units/Button/Button";
import Colors from "../../../theme/colors";

const pjson = require('../../../../package.json');

const Home = ({}) => {

	let version = pjson.version;
	const history = useHistory();

	return (
		<Body withHeader={false} enablePolling={false}>

			<div style={{
				display: "flex",
				flexDirection: "column",
				position: "relative",
				overflowY: "auto",
				flex: 1,
				justifyContent: "center",
				alignItems: "center"
			}}>
				<img src={logo} style={{height: 273}}/>

				<div style={{
					display: "flex",
					flexDirection: "column",
					backgroundColor: '#151515A5',
					padding: 12,
					borderRadius: 16,
					marginBottom: 40,
					alignItems: "center"
				}}>
					<div style={{
						display: "flex",
						flexDirection: "column",
						borderRadius: 16,
						alignItems: "center",
						padding: 20,
						minWidth: 676,
						border: `3px solid ${Colors.marigold}`,
						boxShadow: `0px 0px 6px ${Colors.marigold}`,
					}}>
						<p>El lanzamiento de Producto depende de vosotros.</p>
						<p><b>¿Estáis listos para afrontar el reto?</b></p>
					</div>
				</div>
				<Button title={"REGISTRAR EQUIPO"} onClick={() => history.push("/registro/jugadores")}/>

				<p style={styles.version}>Versión: {version}</p>
			</div>

		</Body>
	)
}

export default Home;
