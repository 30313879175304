import {STAGE_STATUS} from "../../utils/constants";

export const logic = {
    totalScore: 0,
    gameHasStarted: false,
    roomScores: [],
    scenesScores: {
        escenario1: 0,
        escenario2: 0,
        escenario3: 0,
        escenario4: 0,
        escenario5: 0,
        escenario6: 0,
    },
    stagesStatus: [
        {
            num: 1,
            title: "Quiz",
            description: 'Preguntas con 4 opciones de respuesta y solo 1 respuesta válida',
            nextScreen: '/jugador/escenario1/1',
            img: "",
            status: STAGE_STATUS.CURRENT
        },
        {
            num: 2,
            title: "Sentencia crítica",
            description: 'Batería de preguntas tipo verdadero/falso',
            nextScreen: '/jugador/escenario2/1',
            img: "",
            status: STAGE_STATUS.BLOCKED
        },
        {
            num: 3,
            title: "Visto para sentencia",
            description: 'Preguntas con 4 opciones de respuesta y solo 1 respuesta válida',
            nextScreen: '/jugador/escenario3/1',
            img: "",
            status: STAGE_STATUS.BLOCKED
        },
        {
            num: 4,
            title: "Tabla de competidores",
            description: 'Completa la tabla emparejando las etiquetas con los productos correspondientes',
            nextScreen: '/jugador/escenario4',
            img: "",
            status: STAGE_STATUS.BLOCKED
        },
        {
            num: 5,
            title: "Construyendo respuestas",
            description: 'Completa la frase seleccionando las opciones correctas',
            nextScreen: '/jugador/escenario5/1',
            img: "",
            status: STAGE_STATUS.BLOCKED
        },
        {
            num: 6,
            title: "DAFOS",
            description: 'Genera los DAFOS de cada producto',
            nextScreen: '/jugador/escenario6/dafo1',
            img: "",
            status: STAGE_STATUS.BLOCKED
        }
    ],
    "/jugador/escenario1": [
        {
            id: "1",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g1p1b4',
                'g1p2b4',
                'g1p3b2',
                'g1p4b4',
                'g1p5b3',
                'g1p6b4',
                'g1p7b3',
                'g1p8b1',
                'g1p9b3',
                'g1p10b3',
                'g1p11b3',
                'g1p12b4',
                'g1p13b4',
                'g1p14b4',
                'g1p15b4',
                'g1p16b2',
                'g1p17b1',
                'g1p18b3',
                'g1p19b3',
                'g1p20b1',
                'g1p22b4',
                'g1p23b3',
                'g1p24b1',
                'g1p25b2',
                'g1p26b4',
                'g1p27b3',
                'g1p28b2',
                'g1p29b4',
                'g1p30b3',
                'g1p31b4',
                'g1p32b2',
                'g1p33b4',
                'g1p34b3',
                'g1p35b1',
                'g1p36b2',
                'g1p37b2',
                'g1p38b2',
                'g1p39b1',
                'g1p40b2',
                'g1p41b1',
                'g1p42b1',
                'g1p43b4',
                'g1p44b1',
                'g1p45b1',
                'g1p46b1',
                'g1p47b4',
                'g1p48b4',
                'g1p49b4',
                'g1p50b4',
                'g1p51b2',
                'g1p52b4',
                'g1p53b3',
                'g1p54b3',
                'g1p55b3',
                'g1p56b1',
                'g1p57b4',
            ]
        },
    ],
    "/jugador/escenario3": [
        {
            id: "1",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g1p1b2'
            ]
        },
        {
            id: "2",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g2p1b1'
            ]
        },
        {
            id: "3",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g3p1b4'
            ]
        },
        {
            id: "4",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g4p1b1'
            ]
        },
        {
            id: "5",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g5p1b1'
            ]
        },
        {
            id: "6",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g6p1b3'
            ]
        },
        {
            id: "7",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g7p1b2'
            ]
        },
        {
            id: "8",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g8p1b3'
            ]
        },
        {
            id: "9",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g9p1b4'
            ]
        },
        {
            id: "10",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g10p1b1'
            ]
        },
        {
            id: "11",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g11p1b4'
            ]
        },
        {
            id: "12",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g12p1b1'
            ]
        },
        {
            id: "13",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g13p1b2'
            ]
        },
    ],
    "/jugador/escenario4": {
        correctAnswer: 10,
        wrongAnswer: -20,
        elementPositions: [
            [['21'], ['05'], ['35'], ['58'], ['53']],
            [['43'], ['49'], ['15'], ['41'], ['31']],
            [['28'], ['39', '51', '47'], ['39', '51', '47'], ['39', '51', '47'], ['59']],
            [['10'], ['56'], ['26'], ['20'], ['54']],
            [['50'], ['60'], ['24'], ['32'], ['06']],
            [['04'], ['11'], ['48'], ['36'], ['52']],
            [['34'], ['22'], ['13'], ['16'], ['42']],
            [['55'], ['33'], ['02'], ['30'], ['07']],
            [['17'], ['03'], ['40'], ['14'], ['29']],
            [['23'], ['38'], ['08'], ['37'], ['18']],
            [['44'], ['12'], ['27'], ['25'], ['46']],
        ]
    },
    "/jugador/escenario5": [
        {
            id: "1",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g1p1b1',
                'g1p2b3',
                'g1p3b2',
                'g1p4b4'
            ]
        },
        {
            id: "2",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g2p1b3',
                'g2p2b3',
                'g2p3b3',
                'g2p4b4'
            ]
        },
        {
            id: "3",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g3p1b4',
                'g3p2b4',
                'g3p3b2',
                'g3p4b4'
            ]
        },
        {
            id: "4",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g4p1b2',
                'g4p2b1',
                'g4p3b3',
                'g4p4b4'
            ]
        },
        {
            id: "5",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g5p1b1',
                'g5p2b4',
                'g5p3b4',
                'g5p4b1'
            ]
        },
        {
            id: "6",
            correctAnswer: 20,
            wrongAnswer: -10,
            errors: 0,
            answers: [
                'g6p1b3',
                'g6p2b1',
                'g6p3b4',
                'g6p4b3'
            ]
        },

    ],

}
