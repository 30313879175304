import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {ArgumentAxis, BarSeries, Chart, Legend, ValueAxis} from '@devexpress/dx-react-chart-material-ui';
import {Animation, Stack} from '@devexpress/dx-react-chart';
import {content} from "../../../data/scenes/content";

import Body from "../../../components/composed/Body/Body";
import Button from "../../../components/units/Button/Button";

import styles from "./styles";
import {useSelector} from "react-redux";
import {rankingFormatterFromAPI} from "../../../data/formatters/formatters";

const Ranking = () => {
	const history = useHistory();
	const location = useLocation();

	const roomScores = useSelector((state) => state.logic.roomScores);

	const state = location.state ? location.state : {};
	const currentPath = location.pathname;
	const screenContent = content[currentPath];

	const [rankingData, setRankingData] = useState([]);

	const ValueAxisLabel = ({...props}) => <ValueAxis.Label class={'axis-label'} {...props} />
	const AxisLabel = ({...props}) => <ArgumentAxis.Label class={'axis-label'} {...props} />
	const LegendRoot = ({...props}) => <Legend.Root className={'legend-root'} {...props} />
	const LegendLabel = ({...props}) => <Legend.Label className={'legend-label'} {...props} />

	useEffect(() => {
		console.log(roomScores);
		if (roomScores) setRankingData(rankingFormatterFromAPI(roomScores))
	}, [roomScores])

	const getLegendLabels = () => {
		return screenContent.series.map(item => {
			return item.legendLabel
		})
	};

	const handleGoBack = () => {
		let nextPath = state ? state.buttonActionPath : null;
		if (state.buttonTitle && nextPath) {
			history.push(nextPath)
		} else history.goBack()
	};

	return(
		<Body withHeader={screenContent.showHeader} enablePolling={screenContent.enablePolling}>
			<div style={styles.wrapper}>
				{screenContent.showHeader === false && <div style={{marginTop: 20}}/>}
				<h2 style={styles.title}>Ránking</h2>
				<div style={styles.tableContent}>
					<Chart data={rankingData}>
						<ArgumentAxis labelComponent={AxisLabel} tickSize={0}/>

						<ValueAxis labelComponent={ValueAxisLabel} max={1000}/>

						{screenContent.series.map((item, index) => {
							return <BarSeries key={index} name={item.legendLabel} valueField={item.valueField}
											  argumentField={"groupName"}/>
						})}

						<Animation/>

						<Legend position={"bottom"} rootComponent={LegendRoot} labelComponent={LegendLabel}/>

						<Stack stacks={[{series: getLegendLabels()}]}/>
					</Chart>
				</div>
				{!state.disableButton &&
				<Button title={state.buttonTitle ? state.buttonTitle : "Volver"} onClick={handleGoBack}/>}
			</div>
		</Body>
	)
}

export default Ranking;
