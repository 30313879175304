import React, {useEffect} from 'react';
import {getGameService, getScoresService, updateScoresService} from "../../data/services/programServices";
import moment from "moment";
import {setRoomScoresType} from "../../store/actions/setGameStatus";
import {useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";


const Sync = ({}) => {

    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const gameHasStarted = useSelector((state) => state.logic.gameHasStarted)
    const sceneScores = useSelector((state) => state.logic.scenesScores);
    const totalScore = useSelector((state) => state.logic.totalScore)

    let polling = null;
    useEffect(() => {
        checkGameStatus();
        if (!polling) {
            polling = setInterval(() => checkGameStatus(), 5000);
        }

        return function cleanup() {
            clearInterval(polling);
        }

    }, [])

    useEffect(() => {
        console.log("CHANGED sceneScores or totalScore => ", {sceneScores, totalScore})
        updateScoresService(sceneScores.escenario1, sceneScores.escenario2, sceneScores.escenario3, sceneScores.escenario4, sceneScores.escenario5, sceneScores.escenario6)
            .then(r => console.info("Updated scores"))
            .catch(e => console.error("Error updating scores", e));
    }, [sceneScores, totalScore])


    const checkMustStop = (isPlaying, endTime) => {
        const currentPath = location.pathname;
        if (currentPath === "/jugador/ranking") return;
        if (gameHasStarted && !isPlaying) {
            alert("El moderador ha parado el juego, hecha un vistazo a los resultados.");
            history.push("/jugador/ranking")
        } else if (gameHasStarted && (moment() > endTime)) {
            console.log(endTime);
            console.log(moment(endTime));
            console.log(moment());
            alert("Se ha acabado el tiempo, hecha un vistazo a los resultados.");
            history.push("/jugador/ranking")
        }
    }

    const checkGameStatus = () => {
        getGameService().then(r => {
            let isPlaying = parseInt(r.res.is_playing) === 1;
            checkMustStop(isPlaying, r.res.end_time);
        })

        getScoresService().then(r => {
            if (r.res) {
                dispatch(setRoomScoresType(r.res))
            }
        })
    }

    return (null)
}

export default Sync;
