import styled from "styled-components";
import {Button as MuiButton} from "@material-ui/core"

export const PrimaryButton = styled(MuiButton)`
  padding: 8px 24px 8px 24px;
  background-image: var(--degradado);
  font-size: 16px;
  font-weight: bold;
  min-height: 58px;
  color: var(--white) !important;
  margin: 8px 4px;
  --shape-distance: 25px;
  min-width: 190px;
  clip-path: polygon(var(--shape-distance) 0%, calc(100% - var(--shape-distance)) 0%, 100% 50%,
  calc(100% - var(--shape-distance)) 100%, var(--shape-distance) 100%, 0% 50%);

`
