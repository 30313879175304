import colors from "../../../theme/colors";

export default {
    content: {
        border: `1px solid ${colors.sheenGreen}`,
        borderRadius: 6,
        width: 69,
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        margin: 2
    },
    contentSelected: {
        border: `1px solid ${colors.marigold}`,
        backgroundColor: colors.goldFusion,
        borderRadius: 6,
        width: 69,
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        margin: 2
    },
    contentDisabled: {
        border: `1px solid #8E8E8E`,
        backgroundColor: "#333333",
        borderRadius: 6,
        width: 69,
        height: 60,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 2
    },
    name: {
        fontSize: 18,
        fontWeight: 400
    }
}
