import React, {useState} from 'react';

import {useHistory} from "react-router-dom";
import Body from "../../../components/composed/Body/Body";
import Colors from "../../../theme/colors";
import {STAGE_STATUS} from "../../../utils/constants";
import LOCK from '../../../assets/lock.png'
import DONE from '../../../assets/done.png'
import {useSelector} from "react-redux";
import Modal from "../../../components/composed/Modal/Modal";
import introImg from "../../../assets/introImg.png";
import e1 from "../../../assets/e1.png";
import e2 from "../../../assets/e2.png";
import e3 from "../../../assets/e3.png";
import e4 from "../../../assets/e4.png";
import e5 from "../../../assets/e5.png";
import e6 from "../../../assets/e6.png";
import Agencia_medicamneto from '../../../assets/Agencia_medicamento.webp';

const SelectorStage = ({}) => {

	const stagesData = useSelector((state) => state.logic.stagesStatus)
	const history = useHistory();

	const currentStage = stagesData.find(stage => stage.status === STAGE_STATUS.CURRENT);
	const [infoModal1, setInfoModal1] = useState(currentStage.num === 1);
	const [infoModal2, setInfoModal2] = useState(false);
	const [infoModal3, setInfoModal3] = useState(false);

	const [instroModal1, setInstroModal1] = useState(false);
	const [instroModal2, setInstroModal2] = useState(false);

	const handleNextScreen = (screen) => {
		history.push(screen)
	}

	const StageCard = ({stage, index, prevCard, description = "", status = STAGE_STATUS.BLOCKED, nextScreen}) => {
		let marginLeft;
		let minWidth;
		const backgroundImage = [
			e1,
			e2,
			e3,
			e4,
			e5,
			e6
		];


		if (index === 0) {
			marginLeft = 0
		} else {
			marginLeft = -180
		}

		if (index > 0 && prevCard.status === STAGE_STATUS.CURRENT) {
			marginLeft = -20
		}
		if (index > 0 && status === STAGE_STATUS.CURRENT) {
			marginLeft = -220
		}

		if (index === 5 && status === STAGE_STATUS.BLOCKED) {
			minWidth = 180;
		} else {
			minWidth = 360
		}


		if (index === 5 && status === STAGE_STATUS.CURRENT) {
			marginLeft = -220
		}

		if (status === STAGE_STATUS.CURRENT) {
			return (
				<div onClick={() => handleNextScreen(nextScreen)} style={{cursor: "pointer", boxSizing:"border-box", minWidth: minWidth, padding: '16px 16px 42px 16px', height: '100%', zIndex: index +1, marginLeft: marginLeft, width: '16%', background: '#444444', boxShadow: '-10px 0px 11px rgba(0, 0, 0, 0.25)', borderRadius: 6}}>
					<div style={{boxSizing:"border-box", width: '100%', position: "relative", backgroundColor: '#262626', height: '100%', marginBottom: 22, borderRadius: 8, padding: 24, color: "white"}}>
						<div>
							<h2 style={{color: "white", marginTop: 0, marginBottom: 8}}>{stage.title.toUpperCase()}</h2>
							<p style={{color: 'white', maxWidth: '80%', marginTop:0}}>{description}</p>
						</div>
						<img src={backgroundImage[index]} style={{objectFit: "fit", width: '100%', position: "absolute", bottom: 0, left: 0, borderBottomLeftRadius: 8, borderBottomRightRadius: 8}}/>
					</div>
				</div>
			)
		}
		else if (status === STAGE_STATUS.COMPLETED){
			return (
				<div style={{boxSizing:"border-box", minWidth: minWidth, padding: '16px 16px 42px 16px', height: '100%', zIndex: index +1, marginLeft: marginLeft, width: '16%', background: '#444444', boxShadow: '-10px 0px 11px rgba(0, 0, 0, 0.25)', borderRadius: 30}}>
					<div style={{display: "flex", flexDirection: "column", boxSizing:"border-box", width: '100%', backgroundColor: '#262626', height: '100%', marginBottom: 22, borderRadius: 8, padding: 24, color: "white"}}>
						<p style={{color: "white", fontWeight: "bold"}}>ESCENARIO 0{index +1}</p>
						<div style={{flex: 1}}/>
						<img src={DONE} style={{objectFit: "contain", height: 80, width: "auto", maxWidth: 80}}/>
						<div style={{flex: 1}}/>
					</div>
				</div>
			)
		} else {
			return (
				<div style={{boxSizing:"border-box", minWidth: minWidth, padding: '16px 16px 42px 16px', height: '100%', zIndex: index +1, marginLeft: marginLeft, width: '16%', background: '#444444', boxShadow: '-10px 0px 11px rgba(0, 0, 0, 0.25)', borderRadius: 30}}>
					<div style={{display: "flex", flexDirection: "column", boxSizing:"border-box", width: '100%', backgroundColor: '#262626', height: '100%', marginBottom: 22, borderRadius: 8, padding: 24, color: "white"}}>
						<p style={{color: "white", fontWeight: "bold"}}>ESCENARIO 0{index +1}</p>
						<div style={{flex: 1}}/>
						<img src={LOCK} style={{objectFit: "contain", height: 80, width: "auto", maxWidth: 120}}/>
						<div style={{flex: 1}}/>
					</div>
				</div>
			)
		}
	};

	return (
		<Body withHeader showInfoButton={true} onClickInfo={() => setInstroModal1(true)}>
			<div style={{display: "flex", flexDirection: "column", flex: 1, width: '100%'}}>
				<div style={{
					display: "flex",
					flex: 1,
					flexDirection: "column",
					maxHeight: 800,
					maxWidth: '100%',
					overflowX: "auto"
				}}>
					<div style={{
						display: "flex",
						flexDirection: "row",
						marginTop: 21,
						marginBottom: 21,
						marginRight: 12,
						flex: 1,
					}}>
						<div style={{
							width: 4,
							backgroundColor: Colors.paolaVeroneseGreen,
							marginRight: 8,
							boxShadow: `0px 4px 4px ${Colors.paolaVeroneseGreen}`,
							maxHeight: 490,
						}}/>

						<div style={{
							display: "flex",
							flexDirection: "row",
							minHeight: 400,
							maxHeight: 500,
							boxSizing: "border-box",
							flex: 1
						}}>
							{stagesData.map((stage, index) =>
								<StageCard stage={stage} index={index} nextScreen={stage.nextScreen}
										   description={stage.description} status={stage.status}
										   prevCard={stagesData[index - 1]}/>)}
						</div>

					</div>
				</div>
			</div>
			<Modal title={""} open={infoModal1}
				   actions={[{
					   title: "Siguiente", onClick: () => {
						   setInfoModal1(false)
						   setInfoModal2(true)
					   }
				   }]}>
				<img src={introImg} style={{width: '100%'}}/>
				<h4>Anoche se declaró un incendio en la Agencia Española del Medicamento y Productos Sanitarios (AEMPS),
					afortunadamente, no hubo que lamentar víctimas. El incidente ha afectado varias plantas de la
					agencia, incluida la de los servidores, donde se almacenaba la información necesaria para el
					lanzamiento de varios medicamentos para el asma en España.</h4>
			</Modal>
			<Modal title={""} open={infoModal2}
				   actions={[{
					   title: "Siguiente", onClick: () => {
						   setInfoModal2(false)
						   setInfoModal3(true)
					   }
				   }]}>
				<img src={introImg} style={{width: '100%'}}/>
				<h4>Este incidente ha supuesto un problema para el lanzamiento de Producto y la AEMPS ha autorizado a
					Laboratorio para que envíe un equipo de especialistas a la sede de la agencia con la finalidad de
					recuperar la información necesaria para poder realizar el lanzamiento según lo previsto.</h4>
			</Modal>
			<Modal title={""} open={infoModal3}
				   actions={[{
					   title: "Siguiente", onClick: () => {
						   setInfoModal3(false)
						   setInstroModal1(true)
					   }
				   }]}>
				<div style={{display: "flex", width: '100%', justifyContent: 'center'}}>
					<img src={Agencia_medicamneto} alt="Agencia del medicamento"
						 style={{objectFit: "contain", width: '100%'}}/>
				</div>
				<h4>Pertenecéis al equipo de especialistas que tiene que recuperar la información para el lanzamiento
					de Producto, acabáis de llegar a la AEMPS y por lo poco que os han explicado, tendréis que dar más
					de una vuelta para conseguir la información necesaria. El lanzamiento de Producto depende de
					vosotros, ¡Buena suerte!</h4>
			</Modal>
			<Modal title={"Instrucciones"} open={instroModal1}
				   actions={[{
					   title: "Siguiente", onClick: () => {
						   setInstroModal1(false)
						   setInstroModal2(true)
					   }
				   }]}>
				<h4>Acabáis de acceder a la plataforma T2 Wargame. A partir de este momento, se os irán presentando
					diversos escenarios con retos científicos, los cuales deberéis superar con éxito.</h4>
				<h4>Durante la dinámica del juego, se puntuarán vuestros aciertos y se penalizarán vuestros fallos,
					posicionándoos así en el ranking T2.</h4>
				<h4>El objetivo principal de esta dinámica consiste en llegar al final de la formación con la máxima
					puntuación posible.</h4>
				<h4>En el transcurso de la dinámica, dispondréis de <strong>2 comodines gratuitos</strong>, con ellos, podréis solicitar la ayuda de uno de los miembros del grupo de apoyo para abordar una pregunta. Una vez utilizados los comodines gratuitos podréis comprar más por <strong>100 puntos</strong>.</h4>
				<h4><strong>¡ATENCIÓN!</strong> Es muy importante que no cliquéis sobre retroceder o actualizar en el navegador durante la dinámica ¡perderéis todos los puntos y tendréis que comenzar desde el principio!</h4>
				<h4>Os aconsejamos que nombréis a un segundo al mando que vaya tomando nota de todas las respuestas en papel por si se perdiese la información de la plataforma.</h4>
				<h4>El equipo que consiga una puntuación mayor se coronará como vencedor del T2 Wargame.</h4>
			</Modal>
			<Modal title={"Instrucciones"} open={instroModal2}
				   actions={[{
					   title: "Empezar", onClick: () => {
						   setInstroModal2(false)
					   }
				   }]}>
				<h3>Preguntas:</h3>
				<h4 style={{marginBottom: 0, fontWeight: "bold"}}>1. ¿Como podréis avanzar hacia el siguiente
					escenario?</h4>
				<p style={{marginTop: 8}}>Contestando correctamente todas las preguntas que se plantean. Mientras haya
					respuestas incorrectas, no se os permitirá cambiar a la siguiente pantalla.</p>
				<h4 style={{marginBottom: 0, fontWeight: "bold"}}>2. ¿Como podréis obtener puntos?</h4>
				<p style={{marginTop: 8}}>Contestando correctamente las preguntas que se plantean.</p>
				<h4 style={{marginBottom: 0, fontWeight: "bold"}}>3. ¿Podréis perder puntos? </h4>
				<p style={{marginTop: 8}}>Sí, ya que cada respuesta incorrecta os penalizará.</p>
				<h4 style={{marginBottom: 0, fontWeight: "bold"}}>4. ¿Qué podréis hacer si os quedáis bloqueados?</h4>
				<p style={{marginTop: 8}}>Disponéis de comodines ilimitados para contar con la ayuda de un experto del equipo de soporte, los dos primeros serán gratuitos, el resto, tendrán un coste de <strong>100 puntos</strong>.</p>
			</Modal>

		</Body>
	)
}

export default SelectorStage;
