export const getValueFromStorage = (key) => {
	let valueStored = sessionStorage.getItem(key);
	if (!valueStored) {
		valueStored = localStorage.getItem(key);
	}

	return valueStored;
};


export const setValueToStorage = (key, value) => {
	sessionStorage.setItem(key, value);
};
