import Home from "./screens/common/home";
import Stage5 from "./screens/player/stage5";
import Stage1 from "./screens/player/stage1";
import Stage2 from "./screens/player/stage2";
import Stage6 from "./screens/player/stage6";
import Stage4 from "./screens/player/stage4";
import Stage3 from "./screens/player/stage3";
import Register from "./screens/player/register";
import Espera from "./screens/player/espera/espera";
import Manager from "./screens/moderator/manager/manager";
import SelectorStage from "./screens/player/selectorStage/selectorStage";
import Ranking from "./screens/common/ranking/ranking";

export default [
    {path: "/", component: Home},

    {path: "/moderador/manager", component: Manager},
    {path: "/moderador/ranking", component: Ranking},

    {path: "/registro/jugadores", component: Register},
    {path: "/registro/espera", component: Espera},
    {path: "/jugador/selector-escenarios", component: SelectorStage},
    {path: "/jugador/escenario1/:groupId", component: Stage1},
    {path: "/jugador/escenario2/:questionId?", component: Stage2},
    {path: "/jugador/escenario3/:groupId", component: Stage3},
    {path: "/jugador/escenario4", component: Stage4},
    {path: "/jugador/escenario5/:groupId", component: Stage5},
    {path: "/jugador/escenario6/:dafoId", component: Stage6},
    {path: "/jugador/ranking", component: Ranking},
]
