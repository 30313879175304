import React, {Fragment} from 'react';
import {Route} from "react-router-dom";
import routes from './routes';
import {makeStyles} from "@material-ui/core";

function App() {

  const useStyles = makeStyles((theme) => ({
    root: {
      '& > *': {
        margin: theme.spacing(1),
        width: '25ch',
      },
    },
  }));

  const classes = useStyles();

  const renderMainComponent = () => {
    return (
        <Fragment>
          {routes.map(({path, component}, index) => (
              <Route exact path={path} component={component} key={index}/>
          ))}
        </Fragment>
    )
  }
  return (
      <Fragment>{renderMainComponent()}</Fragment>
  );
}

export default App;
