import {composeWithDevTools} from 'redux-devtools-extension';
import {applyMiddleware, createStore} from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers/reducersType';
import {getValueFromStorage, setValueToStorage} from "../utils/utils";

var intialState = {};

const storageKey = "t2wargame_store";

try {
    intialState = getValueFromStorage(storageKey) ? JSON.parse(getValueFromStorage(storageKey)) : {};
} catch (error) {
    console.log('get redux from store Error', error)
}

const saver = (store) => next => action => {
    let stateToSave = store.getState();
    setValueToStorage(storageKey, JSON.stringify({...stateToSave}))
    return next(action);
}

export default createStore(
    reducers,
    intialState,
    composeWithDevTools(
        applyMiddleware(thunk, saver)
    )
);
