import {loginAPI, loginModeradorAPI} from "../api/auth";
import {loginPlayerFormatter} from "../formatters/formatters";
import {gameStatusAPI, resetGameAPI, startGameAPI, stopGameAPI} from "../api/game";
import {addNewScoreAPI, getScoreAPI, getScoresAPI} from "../api/ranking";


export const loginPlayerService = (team_name, avatar, players) => {
    return new Promise((resolve, reject) => {
        let formatted = loginPlayerFormatter(team_name, avatar, players);
        loginAPI(formatted.team_name, formatted.avatar, formatted.players)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData)
                    else throw new Error("Error API in response format")
                } else {
                    throw new Error("Error in authenticate process")
                }
            })
            .catch(error => {
                console.error(error);
                if (error.response && error.response.data && error.response.data.error) reject(error.response.data.error)
                else reject(error.toString())
            })
    })
}

export const loginModeratorService = () => {
    return new Promise((resolve, reject) => {
        loginModeradorAPI()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData)
                } else {
                    throw new Error("Error in authenticate process")
                }
            })
            .catch(error => {
                console.error(error)
                reject(error.toString())
            })
    })
}

export const startGameService = () => {
    return new Promise((resolve, reject) => {
        startGameAPI()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData)
                } else {
                    throw new Error("Error in start game service")
                }
            })
            .catch(error => {
                console.error(error)
                reject(error.toString())
            })
    })
}

export const resetGameService = () => {
    return new Promise((resolve, reject) => {
        resetGameAPI()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData)
                } else {
                    throw new Error("Error in reset game service")
                }
            })
            .catch(error => {
                console.error(error)
                reject(error.toString())
            })
    })
}

export const stopGameService = () => {
    return new Promise((resolve, reject) => {
        stopGameAPI()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData)
                } else {
                    throw new Error("Error in stop game service")
                }
            })
            .catch(error => {
                console.error(error)
                reject(error.toString())
            })
    })
}


export const getGameService = () => {
    return new Promise((resolve, reject) => {
        gameStatusAPI()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData)
                } else {
                    throw new Error("Error in get game status service")
                }
            })
            .catch(error => {
                console.error(error)
                reject(error.toString())
            })
    })
}

export const getScoresService = () => {
    return new Promise((resolve, reject) => {
        getScoresAPI()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData)
                } else {
                    throw new Error("Error in get scores service")
                }
            })
            .catch(error => {
                console.error(error)
                reject(error.toString())
            })
    })
}

export const getOwnScoreService = () => {
    return new Promise((resolve, reject) => {
        getScoreAPI()
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData)
                } else {
                    throw new Error("Error in get score service")
                }
            })
            .catch(error => {
                console.error(error)
                reject(error.toString())
            })
    })
}


export const updateScoresService = (escenario1, escenario2, escenario3, escenario4, escenario5, escenario6) => {
    return new Promise((resolve, reject) => {
        addNewScoreAPI(escenario1, escenario2, escenario3, escenario4, escenario5, escenario6)
            .then(res => {
                if (res.status === 200) {
                    const responseData = res.data;
                    if (responseData) resolve(responseData)
                } else {
                    throw new Error("Error in add new scores service")
                }
            })
            .catch(error => {
                console.error(error)
                reject(error.toString())
            })
    })
}
