import axiosService from "../services/axios";

export const loginAPI = (team_name, avatar, players) => {
    return axiosService.post(
        "/auth/login",
        {
            team_name,
            avatar,
            players
        }
    )
}

export const loginModeradorAPI = () => {
    return axiosService.post(
        "/auth/login_moderator",
        {
            roomid: 1
        }
    )
}
