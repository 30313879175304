import React, {useState} from 'react';
import RadiusButton from "../RadiusButton/RadiusButton";

const CardUnique = ({
                        right, selected, onClick, color, text, width = 480, height = 480, icon,
                        extendedWidth = 680, margin = 4, justification = "", fullyExpanded,
                        validationMode = false, scoreText
                    }) => {

    const [justificationOpacity, setJustificationOpacity] = useState(0);

    let innerCardStyle = {
        height: 154, width: 256, backgroundColor: "#F8F8F8", borderRadius: 2,
        color: color, transform: right ? "rotate(5.35deg)" : "rotate(-5.35deg)", fontSize: 32, display: "flex",
        transition: "all 0.3s",
        justifyContent: "center", alignItems: "center", fontWeight: "bold", marginTop: height * 0.1
    }

    if (fullyExpanded && validationMode) {
        //Full width
        extendedWidth = '100%'
        if (right) {
            innerCardStyle = {
                ...innerCardStyle,
                position: "absolute",
                transform: innerCardStyle.transform + ' translate(340px, -120px)'
            }
        } else {
            innerCardStyle = {
                ...innerCardStyle,
                position: "absolute",
                transform: innerCardStyle.transform + ' translate(-340px, -120px)'
            }
        }
    } else if (!fullyExpanded && validationMode) {
        width = 0;
    }

    if (justification) {
        setTimeout(() => setJustificationOpacity(1), 150)
    }

    return (
        <div style={{
            display: width > 0 ? "flex" : "none",
            width: selected ? extendedWidth : width,
            height: height,
            backgroundColor: "rgba(21,21,21,0.65)",
            opacity: selected ? 1 : 0.8,
            transition: "width 0.3s",
            position: (right) && "absolute",
            right: right && "0",
            visibility: width > 0 ? 1 : "hidden",
            borderRadius: 15,
            margin: margin,
            boxSizing: "border-box",
            cursor: "pointer",
            zIndex: selected ? 20 : 10
        }}
             onClick={onClick}
        >
            <div style={{
                display: "flex", width: '100%', height: 'calc(100% - 32px)', transition: "border 0.3s",
                border: `3px solid ${color}`, margin: 16, padding: 28, boxSizing: "border-box",
                borderRadius: 15, boxShadow: `0px 0px 6px ${color}`, justifyContent: "space-between",
                alignItems: "center", flexDirection: "column", overflow: "hidden"
            }}>
                <div style={innerCardStyle}>
                    {text}
                </div>


                {justification &&
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    opacity: justificationOpacity,
                    transform: justificationOpacity ? "scale(1)" : "scale(2)",
                    transition: "all 0.3s",
                }}>
                    <img src={icon} style={{height: 86, width: 86, margin: 8, marginTop: 0}}/>
                    <h3 style={{color, margin: 8}}>{scoreText}</h3>
                    <span style={{color: "#818181", marginTop: 8,}}>JUSTIFICACIÓN</span>
                    <p dangerouslySetInnerHTML={{__html: justification}}/>
                </div>}

                {!validationMode && <RadiusButton onClick={onClick} selected={selected} color={color}/>}

            </div>
        </div>
    )

}
export default CardUnique;
