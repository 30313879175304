import colors from "../../../theme/colors";

export default {
    container: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    table: {
        borderCollapse: 'collapse'
    },
    tableContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '15px',
        padding: 22
    },
    tableHeader: {
        backgroundColor: colors.marigold,
        borderRadius: 4,
        padding: '8px 16px',
        margin: '8px 28px'
    },
    tableHeaderText: {
        fontSize: 14,
        fontWeight: 400,
        textAlign: 'center',
        marginBlockStart: 0,
        marginBlockEnd: 0
    },
    tableRowTitle: {
        maxWidth: 88,
        minHeight: 44,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: 8
    },
    tableRowTitleText: {
        fontSize: 12,
        fontWeight: 400,
        color: colors.marigold,
        textAlign: 'right'
    },
    borderLeft: {
        borderLeft: `1px solid ${colors.grayWeb}`
    },
    borderBottom: {
        borderBottom: `1px solid ${colors.grayWeb}`
    },
    cell: {
        backgroundColor: colors.jet,
        height: 32,
        borderRadius: 3,
        margin: '0px 12px',
        cursor: 'pointer'
    },
    tagWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    }
}
