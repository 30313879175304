import React from 'react';
import {PrimaryButton} from './primaryButton';
import {SecondaryButton} from './secondaryButton';
import {BUTTON_TYPES} from "../../../utils/constants";


const Button = ({title, type = BUTTON_TYPES.PRIMARY, disabled = false, onClick}) => {
    let customStyle = {}
    if (disabled) customStyle = {...customStyle, opacity: "45%"};
    if (type === BUTTON_TYPES.PRIMARY) {
        return (
            <PrimaryButton style={customStyle} onClick={!disabled ? onClick : null} disabled={disabled}>
                <span>{title}</span>
            </PrimaryButton>
        )
    } else if (type === BUTTON_TYPES.SECONDARY) {
        return (
            <SecondaryButton style={customStyle} onClick={!disabled ? onClick : null} disabled={disabled}>
                <div className={"inner"}>
                    <span>{title}</span>
                </div>
            </SecondaryButton>
        )
    }
};

export default Button
