import styled from "styled-components";
import {Button as MuiButton} from "@material-ui/core"

export const SecondaryButton = styled(MuiButton)`
  padding: 8px 24px 8px 24px;
  background: transparent;
  font-size: 16px;
  font-weight: bold;
  min-height: 58px;
  border: 3px solid var(--sheenGreen);
  color: var(--sheenGreen) !important;
  margin: 8px 4px;
  min-width: 190px;
`
