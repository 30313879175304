import AVATAR_RED from '../assets/avatars/avatares_red_outline_register.png';
import AVATAR_ORANGE from '../assets/avatars/avatares_orange_outline_register.png';
import AVATAR_YELLOW from '../assets/avatars/avatares_yellow_outline_register.png';
import AVATAR_GREEN from '../assets/avatars/avatares_green_outline_register.png';
import AVATAR_CIAN from '../assets/avatars/avatares_cian_outline_register.png';
import AVATAR_BLUE from '../assets/avatars/avatares_blue_outline_register.png';
import AVATAR_PURPLE from '../assets/avatars/avatares_purple_outline_register.png';
import AVATAR_VIOLET from '../assets/avatars/avatares_violet_outline_register.png';
import AVATAR_PINK from '../assets/avatars/avatares_pink_outline_register.png';
import AVATAR_GRAY from '../assets/avatars/avatares_gray_outline_register.png';

export const ROLES = {
	MODERATOR: "moderator",
	PLAYER: "player",
};

export const UNIQUE_SELECTION_TYPES = {
    LONG: 'LONG',
    SHORT: 'SHORT'
};

export const BUTTON_TYPES = {
	PRIMARY: "PRIMARY",
	SECONDARY: "SECONDARY"
};

export const AVATARS = [
	{id: 1, avatar: AVATAR_RED},
	{id: 2, avatar: AVATAR_ORANGE},
	{id: 3, avatar: AVATAR_YELLOW},
	{id: 4, avatar: AVATAR_GREEN},
	{id: 5, avatar: AVATAR_CIAN},
	{id: 6, avatar: AVATAR_BLUE},
	{id: 7, avatar: AVATAR_PURPLE},
	{id: 8, avatar: AVATAR_VIOLET},
	{id: 9, avatar: AVATAR_PINK},
	{id: 10, avatar: AVATAR_GRAY},
];

export const STAGE_STATUS = {
	COMPLETED: "COMPLETED",
	CURRENT: "CURRENT",
	BLOCKED: "BLOCKED"
}
