export default {
    content: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        width: '100%'
    },
    buttonWrapper: {
        marginTop: 22,
        display: 'flex',
        justifyContent: 'center'
    },
    modalWithTagsContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        maxHeight: 2100,
        maxWidth: 942
    },
    previewTag: {
        textAlign: 'center',
    }
}
