import {SAVE_USER_AVATAR, SAVE_USER_TYPE} from "../../actions/actionType";

const initialState = {
    userType: 0,
    selectedAvatar: 1,
}

export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_USER_TYPE:
            return {
                ...state,
                userType: action.payload
            }
        case SAVE_USER_AVATAR:
            return {
                ...initialState,
                selectedAvatar: action.payload
            }
        default:
            return state
    }
}
