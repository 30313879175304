import React, {useEffect, useState} from 'react';

import {useHistory} from "react-router-dom";
import Body from "../../../components/composed/Body/Body";
import logo from "../../../assets/logo.png";
import {
    getGameService,
    loginModeratorService,
    resetGameService,
    startGameService,
    stopGameService
} from "../../../data/services/programServices";
import Button from "../../../components/units/Button/Button";
import {BUTTON_TYPES} from "../../../utils/constants";
import moment from "moment";

const Manager = ({}) => {

    const history = useHistory();

    let polling = null;

    const [activeGame, setActiveGame] = useState(false);
    const [gameEndTime, setGameEndTime] = useState("");

    useEffect(() => {
        loginModeratorService()
            .then(res => {
                if (!res.res) alert("No se ha podido establecer el dinamizador. Intente con otro navegador.")
            })
            .catch(err => {
                console.error(err);
                alert("No se ha podido establecer el dinamizador. Intente con otro navegador.")
            })

        checkGameStatus();
        if (!polling) {
            polling = setInterval(() => checkGameStatus(), 5000);
        }

        return function cleanup() {
            clearInterval(polling);
        }

    }, [])


    const checkGameStatus = () => {
        getGameService().then(r => {
            if (r.res) {
                setActiveGame(parseInt(r.res.is_playing) === 1)
                const endTime = moment(r.res.end_time).format("HH:mm:ss");
                setGameEndTime(endTime)
            }
        })
    }

    const startGame = () => {
        startGameService()
            .then(r => {
                if (r.res) {
                    setActiveGame(parseInt(r.res.is_playing) === 1)
                    const endTime = moment(r.res.end_time).format("HH:mm:ss");
                    setGameEndTime(endTime)
                }
            })
            .catch(e => {
                alert("Ha habido un error iniciando el juego");
                console.error(e);
            })
    }

    const stopGame = () => {
        stopGameService()
            .then(r => {
                if (r.res) {
                    setActiveGame(r.res.is_playing === "1")
                    const endTime = moment(r.res.end_time).format("HH:mm:ss");
                    setGameEndTime(endTime)
                }
            })
            .catch(e => {
                alert("Ha habido un error parando el juego");
                console.error(e);
            })
    }

    const resetRoom = () => {
        let res = window.confirm("Esta acción borrará el progreso y resultado de los equipos. NO DEBE EJECUTARSE si hay una partida en marcha. ¿Seguro?")
        if (res) {
            resetGameService()
                .then(r => {
                    if (r.res) {
                        setActiveGame(r.res.is_playing === "1")
                        const endTime = moment(r.res.end_time).format("HH:mm:ss");
                        setGameEndTime(endTime)
                    }
                    alert("Reseteo correcto. Todos los jugadores deben registrarse de nuevo si quieren volver a hacer la experiencia.")
                })
                .catch(e => {
                    alert("Ha habido un error reseteando el juego");
                    console.error(e);
                })
        }
    }


    return (
        <Body withHeader={false} enablePolling={false}>

            <div style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                overflowY: "auto",
                flex: 1,
                justifyContent: "center",
                alignItems: "center"
            }}>

                <img src={logo} style={{height: 120}}/>
                <h2 style={{margin: 12}}>Manager</h2>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: '#151515A5',
                    marginTop: 20,
                    padding: 12,
                    borderRadius: 16,
                    alignItems: "center"
                }}>
                    <Button title={"EMPEZAR"} onClick={startGame}/>
                    <Button title={"PARAR"} onClick={stopGame}/>
                    <br/>
                    <Button title={"VER RANKING"} type={BUTTON_TYPES.SECONDARY}
                            onClick={() => history.push("/moderador/ranking")}/>
                </div>
                {activeGame ? <h3>Actividad <b>EN MARCHA</b></h3> : <h3>Actividad <b>PARADA</b></h3>}
                {(activeGame && gameEndTime) && <h3 style={{margin: 0}}>Hora de finalización: {gameEndTime}</h3>}
            </div>

            <span style={{position: "fixed", top: 12}} onClick={resetRoom}>Resetear sala</span>
        </Body>
    )
}

export default Manager;
