import {UNIQUE_SELECTION_TYPES} from "../../utils/constants";

export const content = {
    "/jugador/selector-escenarios": {},
    "/jugador/escenario1": [
        {
            id: '1',
            nextScreen: '/jugador/ranking',
            title: '',
            showFinalPhrase: false,
            lastScreenStage: true,
            QUESTIONS: [
                {
                    title: 'Según la ficha técnica, las indicaciones actualmente aprobadas de Producto son:',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "Rinosinusitis crónica con poliposis nasal en adultos.",
                            id: 'g1p2b1'
                        },
                        {
                            title: 'Opción B',
                            description: "Urticaria crónica espontánea (a partir de 12 años).",
                            id: 'g1p2b2'
                        },
                        {
                            title: 'Opción C',
                            description: "Asma alérgica en adultos, adolescentes y niños (6 años en adelante).",
                            id: 'g1p2b3'
                        },
                        {
                            title: 'Opción D',
                            description: "Todas las anteriores.",
                            id: 'g1p2b4'
                        }
                    ],
                },
                {
                    title: 'En el estudio de fase III estudio, en los pacientes con biomarcadores de tipo 2 elevados (EOS ≥ 300 células/μl,  y/o  FeNO ≥ 50 ppb), Producto 300 mg C2S disminuyó la tasa anualizada de exacerbaciones vs. placebo en un porcentaje del:',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "50%",
                            id: 'g1p3b1'
                        },
                        {
                            title: 'Opción B',
                            description: "70%",
                            id: 'g1p3b2'
                        },
                        {
                            title: 'Opción C',
                            description: "60%",
                            id: 'g1p3b3'
                        },
                        {
                            title: 'Opción D',
                            description: "90%",
                            id: 'g1p3b4'
                        }
                    ],
                },
                {
                    title: 'En cuanto a la posología de Producto, seleccione la opción correcta:',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "La dosis mínima es de 75 mg.",
                            id: 'g1p4b1'
                        },
                        {
                            title: 'Opción B',
                            description: "La dosis máxima recomendada es de 600 mg de Producto cada dos semanas.",
                            id: 'g1p4b2'
                        },
                        {
                            title: 'Opción C',
                            description: "La dosis se calcula según peso del paciente y niveles de IgE basal, y el paciente puede requerir de 1 a 4 inyecciones.",
                            id: 'g1p4b3'
                        },
                        {
                            title: 'Opción D',
                            description: "Todas las anteriores.",
                            id: 'g1p4b4'
                        }
                    ],
                },
                {
                    title: '¿Cuál es la canción favorita de Gilda Kaminsky?',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "Te estoy amando locamente (Las Grecas)",
                            id: 'g1p5b1'
                        },
                        {
                            title: 'Opción B',
                            description: "Cucurrucucu paloma (Lola Beltrán)",
                            id: 'g1p5b2'
                        },
                        {
                            title: 'Opción C',
                            description: "Stand by me (B. E. King)",
                            id: 'g1p5b3'
                        },
                        {
                            title: 'Opción D',
                            description: "Dame veneno (Los Chunguitos)",
                            id: 'g1p5b4'
                        }
                    ],
                },
                {
                    title: 'La dosis recomendada en la ficha técnica de Producto en asma es:',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "Para pacientes con asma grave corticodependientes o con asma grave y dermatitis atópica moderada a grave o adultos con RSCcPN grave, una dosis inicial de 600 mg s.c. (2 inyecciones de 300 mg), seguida de 300 mg cada 2 semanas.",
                            id: 'g1p6b1'
                        },
                        {
                            title: 'Opción B',
                            description: "Para el resto de pacientes, una dosis inicial de 400 mg (dos inyecciones de 200 mg), seguida de 200 mg s.c. cada 2 semanas.",
                            id: 'g1p6b2'
                        },
                        {
                            title: 'Opción C',
                            description: "Está indicado en adultos y adolescentes (a partir de 12 años).",
                            id: 'g1p6b3'
                        },
                        {
                            title: 'Opción D',
                            description: "Todas las anteriores.",
                            id: 'g1p6b4'
                        }
                    ],
                }
            ]
        }
    ],
    "/jugador/escenario2": [
        {
            id: "1",
            nextScreen: "/jugador/escenario2/3",
            title: "La inflamación tipo 2 es frecuente en los pacientes con asma.",
            option1: "Verdadero",
            option1Value: 1,
            option2: "Falso",
            option2Value: 2,
            correctOption: 1,
            bonus: 20,
            penalty: -10,
            correctJustification: "El 50% - 70% de los pacientes adultos con asma tienen inflamación tipo 2, la cual engloba una amplia variedad de fenotipos: eosinofílico, mixto y alérgico. Por lo tanto, estos fenotipos son todos de asma tipo 2.",
            inCorrectJustification: "El 50% - 70% de los pacientes adultos con asma tienen inflamación tipo 2, la cual engloba una amplia variedad de fenotipos: eosinofílico, mixto y alérgico. Por lo tanto, estos fenotipos son todos de asma tipo 2. <br><br>Continúa a la siguiente."
        },
        {
            id: "3",
            nextScreen: "/jugador/escenario2/5",
            title: "En pacientes con EOS en sangre ≥300 células/μl, Producto redujo más la tasa anual de exacerbaciones en el estudio estudio que Producto en el estudio estudio.",
            option1: "Verdadero",
            option1Value: 1,
            option2: "Falso",
            option2Value: 2,
            correctOption: 1,
            bonus: 20,
            penalty: -10,
            correctJustification: "Hasta la fecha, no hay estudios comparativos directos entre los diferentes biológicos, sin embargo, en pacientes con EOS en sangre ≥300 células/μl, la reducción en la tasa anual de exacerbaciones en el estudio estudio con Producto (300 mg C2S) fue del 67% frente al 51% con Producto (C8S) en el estudio estudio.",
            inCorrectJustification: "Hasta la fecha, no hay estudios comparativos directos entre los diferentes biológicos, sin embargo, en pacientes con EOS en sangre ≥300 células/μl, la reducción en la tasa anual de exacerbaciones en el estudio estudio con Producto (300 mg C2S) fue del 67% frente al 51% con Producto (C8S) en el estudio estudio.  <br><br>Continúa a la siguiente."
        },
        {
            id: "5",
            nextScreen: "/jugador/escenario2/6",
            title: "Producto es similar a los demás biológicos.",
            option1: "Verdadero",
            option1Value: 1,
            option2: "Falso",
            option2Value: 2,
            correctOption: 2,
            bonus: 20,
            penalty: -10,
            correctJustification: "Visto para sentencia (se verá más adelante).",
            inCorrectJustification: "Visto para sentencia (se verá más adelante).  <br><br>Continúa a la siguiente."
        },
        {
            id: "6",
            nextScreen: "/jugador/escenario2/25",
            title: "El tiempo máximo de seguimiento en los ensayos clínicos de Producto en asma grave es de 148 semanas.",
            option1: "Verdadero",
            option1Value: 1,
            option2: "Falso",
            option2Value: 2,
            correctOption: 1,
            bonus: 20,
            penalty: -10,
            correctJustification: "En los ensayos clínicos de Producto en asma grave, el número total de semanas de seguimiento fue de 148: 52 semanas en el estudio estudio +  96 semanas en el estudio estudio.",
            inCorrectJustification: "En los ensayos clínicos de Producto en asma grave, el número total de semanas de seguimiento fue de 148: 52 semanas en el estudio estudio +  96 semanas en el estudio estudio.  <br><br>Continúa a la siguiente."
        },
        {
            id: "25",
            lastScreenStage: true,
            nextScreen: "/jugador/ranking",
            title: "Todos los biológicos aprobados en asma grave actúan sobre la inflamación tipo 2.",
            option1: "Verdadero",
            option1Value: 1,
            option2: "Falso",
            option2Value: 2,
            correctOption: 1,
            bonus: 20,
            penalty: -10,
            correctJustification: "Actualmente, todos los biológicos aprobados para el asma grave actúan sobre algún mediador de la cascada inflamatoria tipo 2 (IgE, IL-5, IL-4, IL-13, TSLP...). Los biológicos anti-IgE y anti-IL-5 se dirigen a un solo mediador involucrado en dicha cascada, pero no abordan los mediadores clave y centrales de la inflamación tipo 2 (IL-4 e IL-13), los cuales organizan la cascada y sus consecuencias fisiológicas y clínicas. ",
            inCorrectJustification: "Actualmente, todos los biológicos aprobados para el asma grave actúan sobre algún mediador de la cascada inflamatoria tipo 2 (IgE, IL-5, IL-4, IL-13, TSLP...). Los biológicos anti-IgE y anti-IL-5 se dirigen a un solo mediador involucrado en dicha cascada, pero no abordan los mediadores clave y centrales de la inflamación tipo 2 (IL-4 e IL-13), los cuales organizan la cascada y sus consecuencias fisiológicas y clínicas.   <br><br>Continúa a la siguiente."
        },
    ],
    "/jugador/escenario3": [
        {
            id: '1',
            nextScreen: '/jugador/escenario3/12',
            title: '',
            showFinalPhrase: false,
            QUESTIONS: [
                {
                    title: 'El término "Th2 bajo" es sinónimo de  "inflamación tipo 2".',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "Tanto el término Th2 alto como el término Th2 bajo hacen referencia a lo que conocemos como inflamación tipo 2. Actualmente, las guías de practica clínica, como la GEMA, hablan del Th2 alto o bajo para referirse a la inflamación tipo 2.",
                            id: 'g1p1b1'
                        },
                        {
                            title: 'Opción B',
                            description: "El Th2 bajo hace referencia a lo que conocemos como asma no tipo 2. El Th2 alto, en cambio, sí se puede interpretar como sinónimo de presencia de inflamación tipo 2 y, por lo tanto, asociado al asma Tipo 2. Actualmente, las guías de práctica clínica, como la GEMA, diferencian el asma tipo 2 y no tipo 2, en función de los niveles de los biomarcadores de inflamación tipo 2 (EOS, FeNO, IgE y/o necesidad de OCS).",
                            id: 'g1p1b2'
                        },
                        {
                            title: 'Opción C',
                            description: "El término Th2 alto se puede interpretar como inflamación tipo 2, mientras que el término Th2 bajo haría referencia al asma alérgica. De momento, las guías de practica clínica, como la GEMA, siguen sin hablar de asma tipo 2.",
                            id: 'g1p1b3'
                        },
                        {
                            title: 'Opción D',
                            description: "El término Th2 alto se utiliza cuando coexisten varias enfermedades inflamatorias tipo 2, mientras que el término Th2 bajo se utiliza cuando solo se presenta una enfermedad inflamatoria tipo 2, como asma, DA o RSCcPN.",
                            id: 'g1p1b4'
                        }
                    ],
                }
            ]
        },
        {
            id: '12',
            nextScreen: '/jugador/ranking',
            title: '',
            lastScreenStage: true,
            showFinalPhrase: false,
            QUESTIONS: [
                {
                    title: 'Producto es adecuado para los pacientes con asma grave tipo 2, coexistente con otras enfermedades inflamatorias tipo 2, pero para tratar un paciente con tan solo asma grave, prefiero los biológicos actuales por ser más específicos.',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "Los biológicos actuales actúan sobre un único mediador de la inflamación tipo 2, pero no inhiben las citocinas claves y centrales que provocan dicha inflamación (IL-4 e IL-13). Producto, al inhibir la señalización de IL-4 e IL-13, ha demostrado eficacia en varias patologías inflamatorias tipo 2 como dermatitis atópica, RSCcPN y asma, y ésta ha sido evidenciada tanto en pacientes que presentan una de estas patologías de manera individual, como en pacientes que presentan más de una de manera simultánea.",
                            id: 'g12p1b1'
                        },
                        {
                            title: 'Opción B',
                            description: "Los biológicos actuales actúan sobre un único mediador de la inflamación tipo 2, pero no inhiben las citocinas claves y centrales que provocan dicha inflamación (IL-4 e IL-13). Producto, al inhibir la señalización de IL-4 e IL-13, ha demostrado eficacia en varias patologías inflamatorias tipo 2 como dermatitis atópica, RSCcPN y asma, y ésta ha sido evidenciada solo en pacientes que presentan una de estas patologías de manera individual.",
                            id: 'g12p1b2'
                        },
                        {
                            title: 'Opción C',
                            description: "Los biológicos actuales actúan sobre las citocinas claves y centrales que provocan la inflamación tipo 2 (IL-5 e IgE). Producto, al inhibir la señalización de IL-4 e IL-13, ha demostrado eficacia en varias patologías inflamatorias tipo 2 como dermatitis atópica, RSCcPN y asma, y ésta ha sido evidenciada tanto en pacientes que presentan una de estas patologías de manera individual, como en los pacientes que presentan más de una de manera simultánea.",
                            id: 'g12p1b3'
                        },
                        {
                            title: 'Opción D',
                            description: "Los biológicos actuales actúan sobre un único mediador de la inflamación tipo 2, pero no inhiben las citocinas claves y centrales que provocan dicha inflamación (IL-4 e IL-13). Producto, al inhibir la señalización de IL-4 e IL-13, ha demostrado eficacia en varias patologías inflamatorias tipo 2 como dermatitis atópica, RSCcPN y asma, y ésta ha sido evidenciada tanto en pacientes que presentan una de estas patologías de manera individual, como en los pacientes que presentan diabetes tipo II.",
                            id: 'g12p1b4'
                        }
                    ],
                }
            ]
        },
    ],
    "/jugador/escenario4": {
        id: '1',
        nextScreen: '/jugador/ranking',
        title: '',
        showFinalPhrase: false,
        columns: [
            {
                id: "01",
                title: "Producto 1"
            },
            {
                id: "02",
                title: "Producto 2"
            },
            {
                id: "03",
                title: "Producto 3"
            },
            {
                id: "04",
                title: "Producto 4"
            },
            {
                id: "05",
                title: "Producto 5"
            }
        ],
        rows: [
            {
                rowId: "0",
                title: "Laboratorio"
            },
            {
                rowId: "1",
                title: "Principio activo"
            },
            {
                rowId: "2",
                title: "Tipo de anticuerpo monoclonal "
            },
            {
                rowId: "3",
                title: "Mecanismo de acción"
            },
            {
                rowId: "4",
                title: "Posología"
            },
            {
                rowId: "5",
                title: "Indicación"
            },
            {
                rowId: "6",
                title: "Diseño estudio fase III"
            },
            {
                rowId: "7",
                title: "Tasa anualizada de reducción de exacerbaciones"
            },
            {
                rowId: "8",
                title: "Función pulmonar"
            },
            {
                rowId: "9",
                title: "Reducción OCS"
            },
            {
                rowId: "10",
                title: "Calidad de vida y control de síntomas"
            },
        ],
        OPTIONS: [
            {
                id: '02',
                title: `<h5 class="modal-tag-text">Estudio estudio 53%</h5>`
            },
            {
                id: '03',
                title: `<h5 class="modal-tag-text"><strong>EOS en sangre ≥ 300 células/μl (diferencia vs. placebo)</strong></h5><h5 class="modal-tag-text">estudio: 159 mL</h5><h5 class="modal-tag-text">estudio: 116mL</h5>`
            },
            {
                id: '04',
                title: `<h5 class="modal-tag-text">Pacientes en adultos y adolescentes >12 años con asma grave con inflamación tipo 2 no controlada (eosinófilos elevados en sangre y/o FeNO elevada), como tratamiento de mantenimiento adicional.</h5>`
            },
            {
                id: '05',
                title: `<h5 class="modal-tag-text">Laboratorio 2</h5>`
            },
            {
                id: '06',
                title: `<h5 class="modal-tag-text">210 mg C4S vía S.C.</h5>`
            },
            {
                id: '07',
                title: `<h5 class="modal-tag-text">estudio (52 semanas):</h5><h5 class="modal-tag-text">•56% en la población general (vs. placebo).</h5><h5 class="modal-tag-text">•39% en pacientes con < 150 EOS /μL (vs. placebo).</h5><h5 class="modal-tag-text">•70% en pacientes con ≥ 300 EOS /μL (vs. placebo).</h5><h5 class="modal-tag-text">estudio:</h5><h5 class="modal-tag-text">•Sin diferencia estadística respecto al placebo.</h5>`
            },
            {
                id: '08',
                title: `<u class="modal-tag-text">estudio:</u><h5 class="modal-tag-text">•54% reducción dosis ≥ 50 % (vs. 33%)</h5><h5 class="modal-tag-text">•Suspensión uso OCS: diferencia no significativa vs. PBO</h5><h5 class="modal-tag-text">•54% reducción de dosis de OCS a <5mg/día (vs. 32%)</h5>`
            },
            {
                id: '10',
                title: `<h5 class="modal-tag-text">Anti IL-4R</h5>`
            },
            {
                id: '11',
                title: `<h5 class="modal-tag-text">Pacientes adultos con asma grave eosinofílica no contralada a pesar de corticoides inhalados en dosis altas + LABA, como tratamiento de mantenimiento adicional.</h5>`
            },
            {
                id: '12',
                title: `<strong class="modal-tag-text">EOS en sangre ≥300 células/μl (semana 56): </strong><h5 class="modal-tag-text"><strong>ACQ-6</strong> – estudio: dosis C4S diferencia no significativa; -0,29 puntos (dosis C8S) / estudio -0,19 puntos (dosis C4S) y -0,25 puntos (dosis C8S) (vs. placebo).</h5><h5 class="modal-tag-text"><strong>AQLQ</strong> - estudio dosis C4S diferencia no significativa y 0,30 puntos (C8S) / estudio: 0,16 puntos (C4S) y 0,24 puntos  (C8S) (vs. placebo).</h5>`
            },
            {
                id: '13',
                title: `<h5 class="modal-tag-text">•estudio - Objetivo 1º: reducción de la tasa anual de exacerbaciones.</h5><h5 class="modal-tag-text">•estudio - Objetivo 1º: reducción de OCS.</h5>`
            },
            {
                id: '14',
                title: `<h5 class="modal-tag-text">Resultados no significativos vs. placebo.</h5>`
            },
            {
                id: '15',
                title: `<h5 class="modal-tag-text">Producto</h5>`
            },
            {
                id: '16',
                title: `<h5 class="modal-tag-text">•estudio - Objetivo 1º: reducción de la tasa anual de exacerbaciones.</h5>`
            },
            {
                id: '17',
                title: `<strong class="modal-tag-text">EOS en sangre ≥300 células/μl (diferencia vs. placebo): </strong><h5 class="modal-tag-text">estudio - dosis 300 mg C2S: 210 mL</h5><h5 class="modal-tag-text">estudio- dosis 300 mg C2S: 240 mL</h5><h5 class="modal-tag-text">estudio - dosis 300 mg C2S: 320 mL</h5>`
            },
            {
                id: '18',
                title: `<h5 class="modal-tag-text">estudio: sin diferencia estadística respecto a PBO</h5>`
            },
            {
                id: '20',
                title: `<h5 class="modal-tag-text">Anti Ig-E</h5>`
            },
            {
                id: '21',
                title: `<h5 class="modal-tag-text">Laboratorio 1</h5>`
            },
            {
                id: '22',
                title: `<h5 class="modal-tag-text">•estudio/estudio- Objetivo 1º: reducción de la tasa anual de exacerbaciones en pacientes con EOS ≥300 células/µL.</h5><h5 class="modal-tag-text">•estudio- Objetivo 1º: reducción de OCS.</h5>`
            },
            {
                id: '23',
                title: `<u class="modal-tag-text"><strong>estudio-Población total</strong></u><h5 class="modal-tag-text">•86% redujeron o eliminaron el uso de OCS (vs 68%)</h5><h5 class="modal-tag-text">•80% con reducción dosis ≥ 50 % (vs. 53%)</h5><h5 class="modal-tag-text">•52% suspendió el uso OCS (vs 29%)</h5><h5 class="modal-tag-text">•69% con reducción dosis de OCS a<5mg/ (vs. 33%)</h5><u class="modal-tag-text"><strong>estudio-EOS en sangre ≥300 células/μl</strong></u><h5 class="modal-tag-text">•85% redujeron o eliminaron el uso de OCS (vs 63%)</h5><h5 class="modal-tag-text">•85% reducción dosis ≥ 50 % (vs. 54%)</h5><h5 class="modal-tag-text">•60% suspendió el uso OCS (vs. 32%)</h5><h5 class="modal-tag-text">•84% reducción dosis de OCS  a <5mg/día (vs. 40%)</h5>`
            },
            {
                id: '24',
                title: `<h5 class="modal-tag-text">Adultos y adolescentes (> 12 años) 100 mg vía S.C. C4S</h5><h5 class="modal-tag-text">Niños (6-11 años) 40 mg vía S.C. C4S</h5>`
            },
            {
                id: '25',
                title: `<h5 class="modal-tag-text"><strong>AQLQ</strong>: mejora ≥ 0,5 puntos en 60,8% de los pacientes.</h5><h5 class="modal-tag-text"><strong>AQLQ</strong>: mejora ≥ 1 punto en 45 % de los pacientes.</h5>`
            },
            {
                id: '26',
                title: `<h5 class="modal-tag-text">Anti IL-5</h5>`
            },
            {
                id: '27',
                title: `<h5 class="modal-tag-text"><strong>SGRQ</strong>: mejora en -7 puntos (dosis s.c.).</h5><h5 class="modal-tag-text"><strong>ACQ-5</strong>: mejora, pero sin diferencia clínicamente relevante.</h5>`
            },
            {
                id: '28',
                title: `<h5 class="modal-tag-text">Anti IgG4 kappa - 100% humano</h5>`
            },
            {
                id: '29',
                title: `<h5 class="modal-tag-text">estudio: producto 130 mL (diferencia vs. placebo).</h5><h5 class="modal-tag-text">estudio: (W48) producto + 210 mL vs. placebo 40 mL.</h5>`
            },
            {
                id: '30',
                title: `<h5 class="modal-tag-text">•19 % de reducción de exacerbaciones asmáticas vs. placebo en la población general.</h5><h5 class="modal-tag-text">•50% reducción exacerbaciones de asma grave grave (en análisis posterior, en pacientes con IgE ≥ 76 Ul/ml) vs. placebo.</h5>`
            },
            {
                id: '31',
                title: `<h5 class="modal-tag-text">Producto</h5>`
            },
            {
                id: '32',
                title: `<h5 class="modal-tag-text">Dosis S.C. según niveles basales de IgE y peso corporal C2S/C4S.</h5>`
            },
            {
                id: '33',
                title: `<h5 class="modal-tag-text"><strong>EOS en sangre ≥300 células/μl:</strong></h5><h5>estudio Q4W: 45%; Q8W: 51%</h5><h5>estudio Q4W: 36%; Q8W: 28%</h5>`
            },
            {
                id: '34',
                title: `<h5 class="modal-tag-text">•estudio- Objetivo 1º: reducción de la tasa de anual exacerbaciones y mejora la de función pulmonar (cambio en el FEV1).</h5><h5 class="modal-tag-text">•estudio-Objetivo 1º: reducción de OCS.</h5>`
            },
            {
                id: '35',
                title: `<h5 class="modal-tag-text">Laboratorio 3</h5>`
            },
            {
                id: '36',
                title: `<h5 class="modal-tag-text">Pacientes con asma mediada de forma convincente por IgE en adultos, adolescentes y niños (de 6 a <12 años).</h5>`
            },
            {
                id: '37',
                title: `<h5 class="modal-tag-text">Sin datos en los estudios pivotales.</h5><h5 class="modal-tag-text">Experiencia en vida real</h5>`
            },
            {
                id: '38',
                title: `<u class="modal-tag-text"><strong>estudio (85% > 300 cel/ul)</strong></u><h5 class="modal-tag-text">•66 % (C8S) reducción dosis ≥ 50 % (vs. 37%)</h5><h5 class="modal-tag-text">•52% (C8S) suspendió el uso OCS (vs. 19%)</h5><h5 class="modal-tag-text">•59% (C8S) con reducción dosis de OCS a  <5mg/día (vs. 33%)</h5><u class="modal-tag-text"><strong>estudio</strong></u><h5 class="modal-tag-text">•81% suspendió el uso de OCS o reducción de dosis ≤ 5 mg</h5><h5 class="modal-tag-text">•62% suspendió el uso de OCS </h5><h5 class="modal-tag-text">•91% reducción de dosis de OCS a ≤ 5 mg/día</h5>`
            },
            {
                id: '39',
                title: `<h5 class="modal-tag-text">Anti IgG1 kappa - humanizado </h5>`
            },
            {
                id: '40',
                title: `<h5 class="modal-tag-text"><strong>EOS en sangre ≥150 células/μl (diferencia vs. placebo): 98 mL</strong></h5>`
            },
            {
                id: '41',
                title: `<h5 class="modal-tag-text">Producto</h5>`
            },
            {
                id: '42',
                title: `<h5 class="modal-tag-text">•estudio - Objetivo 1º: reducción de la tasa anual de exacerbaciones (semana 52).</h5><h5 class="modal-tag-text">•estudio - Objetivo 1º: reducción de OCS.</h5>`
            },
            {
                id: '43',
                title: `<h5 class="modal-tag-text">Producto</h5>`
            },
            {
                id: '44',
                title: `<h5 class="modal-tag-text"><strong>estudio (semana 52):</strong></h5><h5 class="modal-tag-text"><strong>ACQ-5:</strong> -0,39 (200 mg C2S) y -0,22 (300 mg C2S) (vs. placebo).</h5><h5 class="modal-tag-text"><strong>AQLQ(S):</strong> 0,29 (200 mg C2S) y 0,26 (300 mg C2S) (vs. placebo).</h5>`
            },
            {
                id: '46',
                title: `<h5 class="modal-tag-text"><strong>ACQ-6:</strong> -0,33 puntos (vs. placebo), semana 52.</h5><h5 class="modal-tag-text"><strong>AQLQ:</strong> 0,33 puntos (vs. placebo), semana 52</h5>`
            },
            {
                id: '47',
                title: `<h5 class="modal-tag-text">Anti IgG1 kappa - humanizado </h5>`
            },
            {
                id: '48',
                title: `<h5 class="modal-tag-text">Pacientes adultos, adolescentes y niños (desde 6 a <12 años de edad) con asma eosinofílica refractaria grave, como tratamiento adicional.</h5>`
            },
            {
                id: '49',
                title: `<h5 class="modal-tag-text">Producto</h5>`
            },
            {
                id: '50',
                title: `<h5 class="modal-tag-text">Dosis inicial de 600 mg (dos inyecciones S.C. de 300 mg), seguida de 300 mg C2S.</h5><h5 class="modal-tag-text">Dosis inicial 400 mg (dos inyecciones S.C. de 200 mg), seguida de 200 mg C2S.</h5><h5 class="modal-tag-text">(según tipo de paciente)</h5>`
            },
            {
                id: '51',
                title: `<h5 class="modal-tag-text">Anti IgG1 kappa - humanizado </h5>`
            },
            {
                id: '52',
                title: `<h5 class="modal-tag-text">Pendiente de indicación.</h5>`
            },
            {
                id: '53',
                title: `<h5 class="modal-tag-text">Laboratorio 5</h5>`
            },
            {
                id: '54',
                title: `<h5 class="modal-tag-text">Anti TSLP</h5>`
            },
            {
                id: '55',
                title: `<h5 class="modal-tag-text"><strong>EOS en sangre ≥300 células/μl:</strong></h5><h5 class="modal-tag-text">estudio: 81% (300 mg) y 71% (200 mg)</h5><h5 class="modal-tag-text">estudio: 67% (300 mg) y 66% (200 mg)</h5>`
            },
            {
                id: '56',
                title: `<h5 class="modal-tag-text">Anti IL-5R</h5>`
            },
            {
                id: '58',
                title: `<h5 class="modal-tag-text">Laboratorio 4</h5>`
            },
            {
                id: '59',
                title: `<h5 class="modal-tag-text">Anti IgG 2λ - 100% humano</h5>`
            },
            {
                id: '60',
                title: `<h5 class="modal-tag-text">30 mg mediante vía S.C.C4S para las 3 primeras dosis y luego C8S (mantenimiento).</h5>`
            }
        ]
    },
    "/jugador/escenario5": [
        {
            id: '1',
            nextScreen: '/jugador/escenario5/2',
            title: 'Mecanismo de acción',
            showFinalPhrase: true,
            QUESTIONS: [
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "Producto es el único anticuerpo totalmente humano IgG4",
                            id: 'g1p1b1'
                        },
                        {
                            title: 'Opción B',
                            description: "Producto es el único anticuerpo totalmente humano IgG1",
                            id: 'g1p1b2'
                        },
                        {
                            title: 'Opción C',
                            description: "Producto es el único anticuerpo humanizado IgG2",
                            id: 'g1p1b3'
                        },
                        {
                            title: 'Opción D',
                            description: "Producto es el único anticuerpo humanizado IgG4",
                            id: 'g1p1b4'
                        }
                    ],
                },
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "que bloquea específicamente la IL-4 y la IL-13,",
                            id: 'g1p2b1'
                        },
                        {
                            title: 'Opción B',
                            description: "que bloquea específicamente el receptor de IL-4 e IL-13,",
                            id: 'g1p2b2'
                        },
                        {
                            title: 'Opción C',
                            description: "que bloquea específicamente el receptor de IL-4 (subunidad alfa),",
                            id: 'g1p2b3'
                        },
                        {
                            title: 'Opción D',
                            description: "que bloquea específicamente el receptor de IL-4 (subunidad beta),",
                            id: 'g1p2b4'
                        }
                    ],
                },
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "inhibiendo la señalización de IL-13 y la inflamación alérgica de forma efectiva.",
                            id: 'g1p3b1'
                        },
                        {
                            title: 'Opción B',
                            description: "inhibiendo la señalización de IL-4 e IL-13 y la inflamación de tipo 2 de forma efectiva.",
                            id: 'g1p3b2'
                        },
                        {
                            title: 'Opción C',
                            description: "inhibiendo la señalización de IL-4 y la inflamación eosinofílica de forma efectiva.",
                            id: 'g1p3b3'
                        },
                        {
                            title: 'Opción D',
                            description: "inhibiendo la señalización de IL-5 y la inflamación de tipo 2 de forma efectiva.",
                            id: 'g1p3b4'
                        }
                    ],
                },
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "Tiene recogida en ficha técnica dos indicaciones.",
                            id: 'g1p4b1'
                        },
                        {
                            title: 'Opción B',
                            description: "Tiene recogida en ficha técnica una indicación.",
                            id: 'g1p4b2'
                        },
                        {
                            title: 'Opción C',
                            description: "Tiene recogida en ficha técnica cuatro indicaciones.",
                            id: 'g1p4b3'
                        },
                        {
                            title: 'Opción D',
                            description: "Tiene recogida en ficha técnica tres indicaciones.",
                            id: 'g1p4b4'
                        }
                    ],
                },
            ]
        },
        {
            id: '2',
            nextScreen: '/jugador/escenario5/6',
            title: 'Posología y dispositivo I',
            showFinalPhrase: true,
            QUESTIONS: [
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "La dosis recomendada de Producto en pacientes a partir de 6 años con asma grave que toman corticosteroides orales o que tienen",
                            id: 'g2p1b1'
                        },
                        {
                            title: 'Opción B',
                            description: "La dosis recomendada de Producto en pacientes a partir de 12 años con asma leve, que toman corticosteroides orales o que tienen ",
                            id: 'g2p1b2'
                        },
                        {
                            title: 'Opción C',
                            description: "La dosis recomendada de Producto en pacientes a partir de 12 años con asma grave, que toman corticosteroides orales o que tienen",
                            id: 'g2p1b3'
                        },
                        {
                            title: 'Opción D',
                            description: "La dosis recomendada de Producto en pacientes a partir de 8 años con asma grave, que toman corticosteroides orales o que tienen",
                            id: 'g2p1b4'
                        }
                    ],
                },
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "dermatitis atópica comórbida de moderada a grave o en adultos con RSCcPN grave comorbida, una dosis inicial de 800 mg (dos inyecciones de 300 mg), seguida de 300 mg C2S administrados mediante inyección subcutánea.",
                            id: 'g2p2b1'
                        },
                        {
                            title: 'Opción B',
                            description: "dermatitis atópica comórbida grave o en adultos con RSCcPN moderada-grave comorbida, una dosis inicial de 600 mg (dos inyecciones de 300 mg), seguida de 300 mg C2S administrados mediante inyección subcutánea.",
                            id: 'g2p2b2'
                        },
                        {
                            title: 'Opción C',
                            description: "dermatitis atópica comórbida de moderada a grave o en adultos con RSCcPN grave comorbida, una dosis inicial de 600 mg (dos inyecciones de 300 mg), seguida de 300 mg C2S administrados mediante inyección subcutánea.",
                            id: 'g2p2b3'
                        },
                        {
                            title: 'Opción D',
                            description: "dermatitis atópica comórbida de moderada a grave o en adultos con RSCcPN grave comorbida, una dosis inicial de 600 mg (dos inyecciones de 300 mg), seguida de 300 mg C4S administrados mediante inyección subcutánea.",
                            id: 'g2p2b4'
                        }
                    ],
                },
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "Para el resto de pacientes, sin dosis inicial y 300 mg cada dos semanas administrados mediante inyección subcutánea,",
                            id: 'g2p3b1'
                        },
                        {
                            title: 'Opción B',
                            description: "Para el resto de pacientes, sin dosis inicial y 200 mg semanal administrados mediante inyección subcutánea,",
                            id: 'g2p3b2'
                        },
                        {
                            title: 'Opción C',
                            description: "Para el resto de pacientes, una dosis inicial de 400 mg (dos inyecciones de 200 mg),",
                            id: 'g2p3b3'
                        },
                        {
                            title: 'Opción D',
                            description: "Para el resto de pacientes, una dosis inicial de 600 mg (dos inyecciones de 300 mg),",
                            id: 'g2p3b4'
                        }
                    ],
                },
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "seguida de 200 mg C4S administrados mediante inyección intramuscular.",
                            id: 'g2p4b1'
                        },
                        {
                            title: 'Opción B',
                            description: "seguida de 200 mg C4S administrados mediante inyección subcutánea.",
                            id: 'g2p4b2'
                        },
                        {
                            title: 'Opción C',
                            description: "seguida de 400 mg C2S administrados mediante inyección subcutánea.",
                            id: 'g2p4b3'
                        },
                        {
                            title: 'Opción D',
                            description: "seguida de 200 mg C2S administrados mediante inyección subcutánea.",
                            id: 'g2p4b4'
                        }
                    ],
                },
            ]
        },
        {
            id: '6',
            nextScreen: '/jugador/ranking',
            title: 'Seguridad a largo plazo',
            lastScreenStage: true,
            showFinalPhrase: true,
            QUESTIONS: [
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "En el estudio estudio, se evaluó la seguridad a largo plazo de Producto en 2193 adultos y 89 adolescentes con asma de moderada a grave",
                            id: 'g6p1b1'
                        },
                        {
                            title: 'Opción B',
                            description: "En el estudio estudio, se evaluó la seguridad a largo plazo de Producto en 3193 adultos y 189 adolescentes con asma de moderada a grave",
                            id: 'g6p1b2'
                        },
                        {
                            title: 'Opción C',
                            description: "En el estudio estudio, se evaluó la seguridad a largo plazo de Producto en 2193 adultos y 89 adolescentes con asma de moderada a grave",
                            id: 'g6p1b3'
                        },
                        {
                            title: 'Opción D',
                            description: "En el estudio estudio, se evaluó la seguridad a largo plazo de Producto en 2193 adultos y 189 adolescentes con asma de leve a moderada",
                            id: 'g6p1b4'
                        }
                    ],
                },
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "y se incluyeron pacientes que habían participado en tres estudios pivotales de Producto en asma (estudio, estudio y estudio).",
                            id: 'g6p2b1'
                        },
                        {
                            title: 'Opción B',
                            description: "y se incluyeron pacientes que habían participado en dos estudios pivotales de Producto en asma (estudio y estudio).",
                            id: 'g6p2b2'
                        },
                        {
                            title: 'Opción C',
                            description: "y se incluyeron pacientes que habían participado en todos los estudios  pivotales de Producto en asma, DA y RSCcPN.",
                            id: 'g6p2b3'
                        },
                        {
                            title: 'Opción D',
                            description: "y se incluyeron pacientes que habían participado en ensayos clínicos anteriores de Producto (estudio y estudio).",
                            id: 'g6p2b4'
                        }
                    ],
                },
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "La eficacia, medida como una variable secundaria, fue similar a los resultados observados en los estudios pivotales y se mantuvo hasta 80 semanas.",
                            id: 'g6p3b1'
                        },
                        {
                            title: 'Opción B',
                            description: "La eficacia, medida como una variable secundaria, fue similar a los resultados observados en los estudios pivotales y se mantuvo hasta 52 semanas.",
                            id: 'g6p3b2'
                        },
                        {
                            title: 'Opción C',
                            description: "La eficacia, medida como una variable secundaria, fue similar a los resultados observados en los estudios pivotales y se mantuvo hasta 180 semanas.",
                            id: 'g6p3b3'
                        },
                        {
                            title: 'Opción D',
                            description: "La eficacia, medida como una variable secundaria, fue similar a los resultados observados en los estudios pivotales y se mantuvo hasta las 96 semanas.",
                            id: 'g6p3b4'
                        }
                    ],
                },
                {
                    title: '',
                    type: UNIQUE_SELECTION_TYPES.LONG,
                    options: [
                        {
                            title: 'Opción A',
                            description: "En los adultos con asma dependiente de OCS, hubo una reducción sostenida en las exacerbaciones y una mejora en la FEV1 de hasta 96 semanas, a pesar de la disminución o la interrupción del broncodilatador de acción prolongada.",
                            id: 'g6p4b1'
                        },
                        {
                            title: 'Opción B',
                            description: "En los adultos con asma dependiente de OCS, hubo una reducción sostenida en las exacerbaciones y una mejora en la FEV1 de hasta 96 semanas, a pesar de la disminución o la interrupción del broncodilatador de acción corta.",
                            id: 'g6p4b2'
                        },
                        {
                            title: 'Opción C',
                            description: "En los adultos con asma dependiente de OCS, hubo una reducción sostenida en las exacerbaciones y una mejora en la FEV1 de hasta 96 semanas, a pesar de la disminución o la interrupción de la dosis de OCS oral.",
                            id: 'g6p4b3'
                        },
                        {
                            title: 'Opción D',
                            description: "En los adultos con asma dependiente de OCS, hubo una reducción sostenida en las exacerbaciones y una mejora en la FEV1 de hasta 96 semanas, a pesar de la disminución o la interrupción de la triple terapia con inhaladores.",
                            id: 'g6p4b4'
                        }
                    ],
                },
            ]
        },
    ],
    "/jugador/escenario6": [
        {
            id: "dafo1",
            minimumSelected: 5,
            maximumSelected: 5,
            nextScreen: "/jugador/escenario6/dafo2",
            title: "Lanzamiento futuro de biosimilares de Producto (5 respuestas necesarias).",
            products: [
                {
                    id: "Producto",
                    name: "Producto 1 (principio activo)",
                    options: [
                        {
                            name: "D",
                            id: "dafo22DupixentD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo22DupixentA",
                            correct: true
                        },
                        {
                            name: "F",
                            id: "dafo22DupixentF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo22DupixentO",
                            correct: false
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto 2 (principio activo)",
                    options: [
                        {
                            name: "D",
                            id: "dafo22NucalaD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo22NucalaA",
                            correct: true
                        },
                        {
                            name: "F",
                            id: "dafo22NucalaF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo22NucalaO",
                            correct: false
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto 3 (principio activo)",
                    options: [
                        {
                            name: "D",
                            id: "dafo22XolairD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo22XolairA",
                            correct: true
                        },
                        {
                            name: "F",
                            id: "dafo22XolairF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo22XolairO",
                            correct: false
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto 4 (principio activo)",
                    options: [
                        {
                            name: "D",
                            id: "dafo22FasenraD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo22FasenraA",
                            correct: true
                        },
                        {
                            name: "F",
                            id: "dafo22FasenraF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo22FasenraO",
                            correct: false
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto 5 (principio activo)",
                    options: [
                        {
                            name: "D",
                            id: "dafo22productoD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo22productoA",
                            correct: true
                        },
                        {
                            name: "F",
                            id: "dafo22productoF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo22productoO",
                            correct: false
                        }
                    ]
                }
            ]
        },
        {
            id: "dafo2",
            minimumSelected: 1,
            maximumSelected: 1,
            nextScreen: "/jugador/escenario6/dafo34",
            title: "Claro perfil de paciente que facilita la toma de decisión de tratamiento para algunos clínicos, debido a la fácil identificación del perfil alérgico.",
            products: [
                {
                    id: "Producto",
                    name: "Producto (Producto)",
                    options: [
                        {
                            name: "D",
                            id: "dafo21DupixentD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo21DupixentA",
                            correct: false
                        },
                        {
                            name: "F",
                            id: "dafo21DupixentF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo21DupixentO",
                            correct: false
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto (Producto)",
                    options: [
                        {
                            name: "D",
                            id: "dafo21NucalaD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo21NucalaA",
                            correct: false
                        },
                        {
                            name: "F",
                            id: "dafo21NucalaF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo21NucalaO",
                            correct: false
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto (Producto)",
                    options: [
                        {
                            name: "D",
                            id: "dafo21XolairD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo21XolairA",
                            correct: false
                        },
                        {
                            name: "F",
                            id: "dafo21XolairF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo21XolairO",
                            correct: true
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto (Producto)",
                    options: [
                        {
                            name: "D",
                            id: "dafo21FasenraD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo21FasenraA",
                            correct: false
                        },
                        {
                            name: "F",
                            id: "dafo21FasenraF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo21FasenraO",
                            correct: false
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto",
                    options: [
                        {
                            name: "D",
                            id: "dafo21productoD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo21productoA",
                            correct: false
                        },
                        {
                            name: "F",
                            id: "dafo21productoF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo21productoO",
                            correct: false
                        }
                    ]
                }
            ]
        },
        {
            id: "dafo34",
            minimumSelected: 1,
            maximumSelected: 1,
            lastScreenStage: true,
            nextScreen: "/jugador/ranking",
            title: "Reducción inconsistente de la exacerbación en los estudios pivotales de fase 3 (estudio vs. estudio).",
            products: [
                {
                    id: "Producto",
                    name: "Producto (Producto)",
                    options: [
                        {
                            name: "D",
                            id: "dafo33DupixentD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo33DupixentA",
                            correct: false
                        },
                        {
                            name: "F",
                            id: "dafo33DupixentF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo33DupixentO",
                            correct: false
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto (Producto)",
                    options: [
                        {
                            name: "D",
                            id: "dafo33NucalaD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo33NucalaA",
                            correct: false
                        },
                        {
                            name: "F",
                            id: "dafo33NucalaF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo33NucalaO",
                            correct: false
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto (Producto)",
                    options: [
                        {
                            name: "D",
                            id: "dafo33XolairD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo33XolairA",
                            correct: false
                        },
                        {
                            name: "F",
                            id: "dafo33XolairF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo33XolairO",
                            correct: false
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto (Producto)",
                    options: [
                        {
                            name: "D",
                            id: "dafo33FasenraD",
                            correct: true
                        },
                        {
                            name: "A",
                            id: "dafo33FasenraA",
                            correct: false
                        },
                        {
                            name: "F",
                            id: "dafo33FasenraF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo33FasenraO",
                            correct: false
                        }
                    ]
                },
                {
                    id: "Producto",
                    name: "Producto",
                    options: [
                        {
                            name: "D",
                            id: "dafo33productoD",
                            correct: false
                        },
                        {
                            name: "A",
                            id: "dafo33productoA",
                            correct: false
                        },
                        {
                            name: "F",
                            id: "dafo33productoF",
                            correct: false
                        },
                        {
                            name: "O",
                            id: "dafo33productoO",
                            correct: false
                        }
                    ]
                }
            ]
        }
    ],
    "/jugador/ranking": {
        enablePolling: true,
        showHeader: true,
        series: [
            {
                legendLabel: 'ESCENARIO 01',
                valueField: 'stage01'
            },
            {
                legendLabel: 'ESCENARIO 02',
                valueField: 'stage02'
            },
            {
                legendLabel: 'ESCENARIO 03',
                valueField: 'stage03'
            },
            {
                legendLabel: 'ESCENARIO 04',
                valueField: 'stage04'
            },
            {
                legendLabel: 'ESCENARIO 05',
                valueField: 'stage05'
            },
            {
                legendLabel: 'ESCENARIO 06',
                valueField: 'stage06'
            },
        ]
    },
    "/moderador/ranking": {
        enablePolling: true,
        showHeader: false,
        series: [
            {
                legendLabel: 'ESCENARIO 01',
                valueField: 'stage01'
            },
            {
                legendLabel: 'ESCENARIO 02',
                valueField: 'stage02'
            },
            {
                legendLabel: 'ESCENARIO 03',
                valueField: 'stage03'
            },
            {
                legendLabel: 'ESCENARIO 04',
                valueField: 'stage04'
            },
            {
                legendLabel: 'ESCENARIO 05',
                valueField: 'stage05'
            },
            {
                legendLabel: 'ESCENARIO 06',
                valueField: 'stage06'
            },
        ]
    }
}
