import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {content} from "../../../data/scenes/content";
import {useDispatch, useSelector} from "react-redux";
import Body from "../../../components/composed/Body/Body";
import Colors from "../../../theme/colors";
import CardUnique from "../../../components/units/CardUnique/CardUnique";
import Button from "../../../components/units/Button/Button";
import wrongIcon from "../../../assets/wrong.png"
import correctIcon from "../../../assets/correct.png"
import correctPaolaIcon from "../../../assets/correct-paola-green.png"
import {saveSceneScoreType} from "../../../store/actions/saveSceneScore";
import Modal from "../../../components/composed/Modal/Modal";
import {completeStage} from "../../../store/actions/stagesType";


const Stage2 = ({}) => {

    const history = useHistory();
    const currentPath = "/jugador/escenario2";
    const screenContent = content[currentPath];
    console.log("Screen content =>", screenContent);
    const logic = useSelector((state) => state.logic[currentPath])
    console.log("Screen logic => ", logic)

    const dispatch = useDispatch()

    const [selectedOption, setSelectedOption] = useState(null);
    const [justification, setJustification] = useState(null);
    const [isExpanded1, setIsExpanded1] = useState(null);
    const [isExpanded2, setIsExpanded2] = useState(null);
    const [currentQuestionData, setCurrentQuestionData] = useState({});
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
    const [validationMode, setValidationMode] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const [initialModal, setInitialModal] = useState(true);
    const [infoModal, setInfoModal] = useState(false);
    const [endModal, setEndModal] = useState(false);


    let cardWidth = 430;
    let card1ExtendedWidth = 600;
    let card2ExtendedWidth = 600;
    let cardHeight = 380;
    let cardsMargin = 4;


    let {questionId} = useParams();

    if (!questionId) {
        console.error("Missing questionId parameter");
        questionId = 1;
    }

    useEffect(() => {
        let questionIndex = screenContent.findIndex((item) => item.id === questionId);
        setCurrentQuestionIndex(questionIndex);
        setCurrentQuestionData(screenContent[questionIndex])
        setSelectedOption("");
        setJustification("");
        setIsExpanded1("");
        setIsExpanded2("");
        setValidationMode("");
    }, [questionId])

    const onSelect = (option) => {
        setSelectedOption(option)
    }

    const goToNextScreen = () => {
        if (currentQuestionData.lastScreenStage) {
            dispatch(completeStage());
            setEndModal(true);
        } else history.push(currentQuestionData.nextScreen)
    }

    const validateOrNext = () => {
        if (isExpanded1 || isExpanded2) {
            goToNextScreen();
            return;
        }
        let isCorrect = selectedOption === currentQuestionData.correctOption;
        if (isCorrect) {
            setButtonDisabled(true);
            setTimeout(() => dispatch(saveSceneScoreType(currentQuestionData.bonus, "escenario2")), 1800)
            setTimeout(() => {
                setJustification(currentQuestionData.correctJustification)
                setButtonDisabled(false);
            }, 1500);
        } else {
            setButtonDisabled(true);
            setTimeout(() => dispatch(saveSceneScoreType(currentQuestionData.penalty, "escenario2")), 1800)
            setTimeout(() => {
                setButtonDisabled(false);
                setJustification(currentQuestionData.inCorrectJustification)
            }, 1500);
        }
        if (selectedOption === currentQuestionData.option1Value) {
            setIsExpanded1(true)
            setIsExpanded2(false)
        }
        if (selectedOption === currentQuestionData.option2Value) {
            setIsExpanded1(false)
            setIsExpanded2(true)
        }
        setValidationMode(true);


        //TODO update score on redux
    }

    let color1 = Colors.sheenGreen;
    let color2 = Colors.paolaVeroneseGreen;
    let icon1 = correctIcon;
    let icon2 = correctPaolaIcon;
    let scoreText = `Has ganado ${currentQuestionData.bonus} puntos`;

    let isCorrect = selectedOption === currentQuestionData.correctOption;


    if (justification && !isCorrect) {
        color1 = Colors.tenneTawny;
        color2 = Colors.tenneTawny;
        icon1 = icon2 = wrongIcon;
        scoreText = `Has perdido ${Math.abs(currentQuestionData.penalty)} puntos`;
    }


    return (
        <Body showInfoButton={true} onClickInfo={() => setInfoModal(true)}>
            {currentQuestionData && <div style={{overflowY: "auto", width: '100%'}}>
                <div style={{margin: '0 auto', textAlign: "center"}}>
                    <span style={{
                        fontSize: 16,
                        color: "#818181"
                    }}>{currentQuestionIndex + 1}/{screenContent.length}</span>
                    <h3><i>{currentQuestionData.title}</i></h3>
                </div>
                <div style={{
                    display: "flex",
                    position: "relative",
                    width: (cardWidth * 2) + (cardsMargin * 4),
                    margin: '0 auto',
                    height: cardHeight
                }}>
                    <CardUnique key={"option1-" + currentQuestionIndex} text={currentQuestionData.option1}
                                color={color1} onClick={() => onSelect(currentQuestionData.option1Value)}
                                selected={selectedOption === currentQuestionData.option1Value} width={cardWidth}
                                height={cardHeight} extendedWidth={card1ExtendedWidth} validationMode={validationMode}
                                margin={cardsMargin} justification={justification} icon={icon1}
                                scoreText={validationMode && scoreText}
                                fullyExpanded={isExpanded1}/>
                    <CardUnique key={"option2-" + currentQuestionIndex} right text={currentQuestionData.option2}
                                color={color2}
                                onClick={() => setSelectedOption(currentQuestionData.option2Value)}
                                selected={selectedOption === currentQuestionData.option2Value} width={cardWidth}
                                height={cardHeight} extendedWidth={card2ExtendedWidth} validationMode={validationMode}
                                margin={cardsMargin} justification={justification} icon={icon2}
                                scoreText={validationMode && scoreText}
                                fullyExpanded={isExpanded2}/>
                </div>

                <div style={{
                    display: "flex",
                    width: '100%',
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: 20
                }}>
                    <Button title={validationMode ? "Siguiente" : "Comprobar"}
                            onClick={() => validateOrNext()}
                            disabled={buttonDisabled || !selectedOption}/>
                </div>
            </div>}
            <Modal title={"Escenario 2: Sentencia crítica"} open={questionId === '1' && initialModal} actions={[{
                title: "CONTINUAR", onClick: () => {
                    setInitialModal(false);
                    setInfoModal(true)
                }
            }]}>
                <h4>Por fin hemos llegado el despacho que nos habían dicho, mirad, hay un hombre allí, vamos a preguntarle.</h4>

                <div style={{backgroundColor: Colors.artichoke, padding: 12, borderRadius: 6}}>
                    <span style={{fontWeight: "bold"}}>Técnico</span>
                    <p>
                        Supongo que ya sabréis lo que ha sucedido, ¿no? Vaya faena el tema del incendio. Imagino que
                        vendréis para hablar con mi jefa; ha salido un momento, volverá en unos minutos. Mientras
                        esperamos, os informo de que yo estuve en la preparación del dosier para el lanzamiento de
                        Producto, por lo que podemos comentar lo que queráis.
                    </p>
                </div>

            </Modal>
            <Modal title={"Instrucciones"} open={infoModal}
                   actions={[{title: "EMPEZAR", onClick: () => setInfoModal(false)}]}>
                <h4 style={{marginTop: 0}}>A continuación, se os mostrará un listado con varias afirmaciones; algunas de
                    ellas serán correctas, mientras que otras estarán mal formuladas.</h4>
                <h4>
                    Seleccionad si la afirmación es verdadera o falsa y clicad sobre ‘’siguiente’’.
                    Todas las afirmaciones que sean incorrectas, deberéis corregirlas posteriormente en el escenario 3.
                </h4>
                <h4>El sistema os confirmará de manera automática si habéis acertado o no y podréis leer la
                    justificación de las afirmaciones correctas.</h4>

                <ul>
                    <li><strong>Objetivo:</strong> determinar correctamente la veracidad de todas las afirmaciones
                        mostradas.
                    </li>
                    <li><strong>Penalizaciones:</strong> env&iacute;o de respuestas incorrectas.</li>
                    <li><strong>Puntuaci&oacute;n:</strong>
                        <ul>
                            <li>Respuesta correcta &rarr; +20 puntos</li>
                            <li>Respuesta incorrecta &rarr; -10 puntos</li>
                        </ul>
                    </li>
                </ul>
                <h4>Recordad que disponéis de comodines en caso de necesitar ayuda.</h4>


            </Modal>
            <Modal title={"¡Bien hecho!"} open={endModal} actions={[{
                title: "CONTINUAR", onClick: () => {
                    setEndModal(false);
                    history.push(currentQuestionData.nextScreen, {
                        buttonTitle: "Continuar",
                        buttonActionPath: "/jugador/selector-escenarios"
                    })
                }
            }]}>
                <div style={{backgroundColor: Colors.artichoke, padding: 12, borderRadius: 6}}>
                    <span style={{fontWeight: "bold"}}>Técnico</span>
                    <h4>
                        Vaya, parece que hace demasiado tiempo que revisé la documentación y confundo algunos conceptos.
                        ¿Podríais ayudarme a aclarar algunos temas?
                    </h4>
                </div>

            </Modal>
        </Body>
    )

}

export default Stage2;
