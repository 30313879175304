import styled from "styled-components";

export const HeaderButton = styled.div`
  height: 24px;
  width: 24px;
  background-color: #202020;
  border-radius: 6px;
  margin-left: 28px;
  transition: all 0.3s;
  padding: 7px;
  cursor: ${props => props.clickable ? "pointer" : "inherit"};

  :hover {
    background-color: ${props => props.clickable && '#F2A90020'}
  }

`

