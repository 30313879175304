import React from 'react';
import {style} from './style';
import {RadiusButtonSelected} from './RadiusButtonSelected';
import {RadiusButtonDefault} from './RadiusButtonDefault';
import Colors from "../../../theme/colors";


const RadiusButton = ({selected = false, onClick, color = Colors.sheenGreen}) => {
    let doughnut = style.doughnut
    let innerCircle = style.innerCircle

    return (
        <RadiusButtonDefault style={{...doughnut, border: `4px solid ${color}`}} onClick={onClick}>
            {selected && <RadiusButtonSelected style={{...innerCircle, backgroundColor: color}} onClick={onClick}/>}
        </RadiusButtonDefault>
    )
};

export default RadiusButton;
