import React, {useState} from 'react';

import QuestionGroups from "../../../components/composed/QuestionGroups";
import Modal from "../../../components/composed/Modal/Modal";
import Colors from "../../../theme/colors";
import {content} from "../../../data/scenes/content";
import {logic} from "../../../data/scenes/logic";
import {useHistory, useParams} from "react-router-dom";
import Body from "../../../components/composed/Body/Body";
import {useDispatch} from "react-redux";
import {completeStage} from "../../../store/actions/stagesType";

const Stage1 = () => {

    const [initialModal, setInitialModal] = useState(true);
    const [infoModal, setInfoModal] = useState(false);
    const [endModal, setEndModal] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const escenario = 1;
    const {groupId} = useParams();
    const currentPath = `/jugador/escenario${escenario}`;
    let groupContent = content[currentPath].find(item => item.id === groupId);
    let groupLogic = logic[currentPath].find(item => item.id === groupId);
    let href = window.location.href;

    const handleFinishState = () => {
        if (groupContent.lastScreenStage) {
            dispatch(completeStage());
            setEndModal(true);
        } else history.push(groupContent.nextScreen)
    }

    return (
        <Body onClickInfo={() => setInfoModal(true)} showInfoButton={true}
              onClickRanking={() => alert("Completa este escenario antes de ver el ranking")}>
            <QuestionGroups escenario={escenario} groupId={groupId} currentPath={currentPath}
                            groupContent={groupContent}
                            groupLogic={groupLogic} href={href} deselectWrong blockCorrect autonumberQuestions
                            handleFinishStage={handleFinishState}/>
            <Modal title={"Escenario 1: Quiz"} open={initialModal} actions={[{
                title: "CONTINUAR", onClick: () => {
                    setInitialModal(false);
                    setInfoModal(true)
                }
            }]}>
                <h4>Acabamos de llegar a la AEMPS, parece ser que el vigilante de seguridad no nos va a dejar pasar tan fácilmente.</h4>
                <div>
                    <div style={{
                        backgroundColor: Colors.artichoke,
                        padding: 12,
                        borderRadius: 6,
                        marginBottom: 8,
                        maxWidth: '80%'
                    }}>
                        <span style={{fontWeight: "bold"}}>Vigilante de seguridad de la AEMPS</span>
                        <p>¡Eh! ¡No podéis entrar aquí! ¿Quiénes sois?</p>
                    </div>
                    <div style={{
                        backgroundColor: "#414832",
                        padding: 12,
                        borderRadius: 6,
                        marginLeft: '20%',
                        marginBottom: 8,
                        maxWidth: '80%'
                    }}>
                        <span style={{fontWeight: "bold"}}>Equipo Laboratorio</span>
                        <p>Somos de Laboratorio, venimos a ayudar con la recuperación de la información que se ha
                            perdido en
                            el incendio.</p>
                    </div>
                    <div style={{backgroundColor: Colors.artichoke, padding: 12, borderRadius: 6, maxWidth: '80%'}}>
                        <span style={{fontWeight: "bold"}}>Vigilante de seguridad de la AEMPS</span>
                        <p>¿Sí? ¿No os importará demostrarlo contestándome una serie de preguntas, verdad? Ha venido
                            mucho curioso de otras compañías queriendo obtener información.</p>
                    </div>
                </div>
            </Modal>
            <Modal title={"Instrucciones"} open={infoModal}
                   actions={[{title: "Aceptar", onClick: () => setInfoModal(false)}]}>
                <h4 style={{marginTop: 0}}>A continuación, se os mostrará un listado de preguntas que deberéis contestar correctamente. Tan solo una de las cuatro opciones mostradas será la correcta.</h4>
                <h4>Una vez hayáis seleccionado todas las respuestas, tendréis que clicar en ‘’siguiente’’.</h4>
                <h4>Cuando hayáis enviado <strong>todas las respuestas</strong>, el sistema os informará del número de fallos que habéis cometido. Tendréis que buscar los errores cometidos entre todo el listado de preguntas. Las respuestas erróneas se habrán borrado, permitiéndoos así contestar de nuevo a las preguntas falladas.</h4>
                <h4>
                    Cuando hayáis contestado todas las preguntas correctamente, podréis avanzar hacia el siguiente escenario.
                </h4>
                <ul>
                    <li><strong>Objetivo:</strong> responder todas las preguntas correctamente.
                    </li>
                    <li><strong>Penalizaciones:</strong> env&iacute;o de respuestas incorrectas.</li>
                    <li><strong>Puntuaci&oacute;n:</strong>
                        <ul>
                            <li>Respuesta correcta &rarr; +20 puntos</li>
                            <li>Respuesta incorrecta &rarr; -10 puntos</li>
                        </ul>
                    </li>
                </ul>

                <h4>Recordad que disponéis de comodines en caso de necesitar ayuda.</h4>

            </Modal>
            <Modal title={"¡Bien hecho!"} open={endModal} actions={[{
                title: "CONTINUAR", onClick: () => {
                    setEndModal(false);
                    history.push(groupContent.nextScreen, {
                        buttonTitle: "Continuar",
                        buttonActionPath: "/jugador/selector-escenarios"
                    })
                }
            }]}>
                <div style={{
                    backgroundColor: Colors.artichoke,
                    padding: 12,
                    borderRadius: 6,
                    marginBottom: 8,
                    maxWidth: '80%'
                }}>

                    <span style={{fontWeight: "bold"}}>Vigilante de seguridad de la AEMPS</span>
                    <p>De acuerdo. Habéis demostrado que sois de Laboratorio, podéis pasar. En la segunda planta,
                        encontraréis a la persona encargada de la revisión de la documentación de estos productos.</p>
                </div>

            </Modal>
        </Body>
    )

}

export default Stage1;
