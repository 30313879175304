import React from 'react';
import {InputStyled} from "./InputStyled";

const Input = ({id, placeholder, value = "", onChange, customStyle}) => {
        return (
            <InputStyled style={customStyle} type={"text"} id={id} placeholder={placeholder} value={value} onChange={onChange}/>
        )
};

export default Input;
