import React from 'react';
import styled from "styled-components";

export const InputStyled = styled.input`
  padding: 16px 16px 16px 20px;
  border:1px solid var(--dimGray);
  border-radius: 4px;
  background: var(--dimGray);
  font-size: 15px;
  font-family: Aller;
  color: white;

  :focus {
    outline: none !important;
    border:1px solid var(--marigold);
    box-shadow: 0 0 5px var(--marigold);
  }

  ::placeholder {
    color: var(--quickSilver);
    font-size: 16px;
  }
  
`;
