import React from 'react';

import styles from "./styles";
import colors from '../../../theme/colors';

const ModalTag = ({title, onClick, isCurrentOptionSelected, isOptionDisable}) => {
    return (
        <div onClick={onClick}
             className={isOptionDisable ? "" : "modal-tag-stage4"}
             style={{
                 ...styles.content,
                 opacity: isOptionDisable ? 0.45 : 1,
                 cursor: isOptionDisable ? 'auto' : 'pointer',
                 border: isCurrentOptionSelected ? `1px solid ${colors.marigold}` : `1px solid ${colors.white}`
             }}>
            <p style={{...styles.text, color: isCurrentOptionSelected ? colors.marigold : colors.white}} dangerouslySetInnerHTML={{__html: title}} />
        </div>
    )
};

export default ModalTag;
