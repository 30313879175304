import {SET_GAME_STATUS, SET_ROOM_SCORES_STATUS} from "./actionType";

export const setGameStatusType = ({gameHasStarted, gameActive, gameEndTime}) => {
    return {
        type: SET_GAME_STATUS,
        payload: {gameHasStarted, gameActive, gameEndTime}
    }
}

export const setRoomScoresType = (scores) => {
    return {
        type: SET_ROOM_SCORES_STATUS,
        payload: scores
    }
}
