import {QUESTION_GROUPS_TYPE} from "./actionType";

export const questionGroupsType = (score, groupId, errors, currentPath) => {
    return {
        type: QUESTION_GROUPS_TYPE,
        payload: {
            score,
            groupId,
            errors,
            currentPath
        }
    }
}
