import React, {useEffect, useState} from 'react';

import {useParams} from "react-router";

import Body from "../../../components/composed/Body/Body";
import Button from "../../../components/units/Button/Button";
import Dafo from "../../../components/composed/Dafo";
import Modal from "../../../components/composed/Modal/Modal";
import Lottie from "lottie-react";
import wellDone from "../../../assets/confetti.json";

import styles from "./styles";
import {useDispatch} from "react-redux";
import {content} from "../../../data/scenes/content";
import {saveSceneScoreType} from "../../../store/actions/saveSceneScore";


const Stage6 = ({history}) => {

    const [initialModal, setInitialModal] = useState(true);
    const [infoModal, setInfoModal] = useState(false);


    const dispatch = useDispatch();

    const {dafoId} = useParams();
    let currentPath = `/jugador/escenario6`;

    let dafoIndex = content[currentPath].findIndex(item => item.id === dafoId);
    const disableScores = false;


    const [optionSelected, setOptionSelected] = useState([]);
    const [maximumReached, setMaximumReached] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [endModal, setEndModal] = useState(false);
    const [correctModal, setCorrectModal] = useState(false);
    const [currentDafoData, setCurrentDafoData] = useState(content[currentPath][dafoIndex]);
    const [totalScore, setTotalScore] = useState(0);

    useEffect(() => {
        if (optionSelected.length === currentDafoData.maximumSelected) {
            setMaximumReached(true)
        } else {
            setMaximumReached(false)
        }

        setCurrentDafoData(content[currentPath][dafoIndex]);

    }, [optionSelected, dafoIndex])

    const handleSelectOption = optionId => {
        const arrayOfOptions = [...optionSelected];

        const isIncluded = arrayOfOptions.includes(optionId)

        if(isIncluded) {
            const index = arrayOfOptions.findIndex(item => item === optionId)
            arrayOfOptions.splice(index, 1)

        } else if (!isIncluded && arrayOfOptions.length < currentDafoData.maximumSelected) {
            arrayOfOptions.push(optionId)

        }

        setOptionSelected(arrayOfOptions)
    };

    const showIndexOfQuestion = () => {
        const totalOfQuestions = content[currentPath].length;

        return (
            <div style={styles.questionIndex}>
                <p style={styles.questionIndexText}>{dafoId.replace("dafo", "")}</p>
                <p style={styles.questionIndexText}> / </p>
                <p style={styles.questionIndexText}>{totalOfQuestions}</p>
            </div>
        )
    };

    const handleOnNextScreen = () => {
        dispatch(saveSceneScoreType(getScore(), 'escenario6'))

        if(numErrorsInSelection() !== 0) {
            setErrorModal(true)
        } else {
            setCorrectModal(true)
        }

    };

    const numErrorsInSelection = () => {
        const allOptions = getArrayWithAllOptions()
        let numErr = 0;

        for(let i = 0; i < allOptions.length; i++) {
            for(let j = 0; j < optionSelected.length; j++) {
                if(allOptions[i].id === optionSelected[j] && !allOptions[i].correct) {
                    numErr++;
                }
            }
        }
        return numErr;
    };

    const getArrayWithAllOptions = () => {
        const allCurrentProducts = currentDafoData.products
        const options = allCurrentProducts.map((item) => {
            return item.options
        })

        return [].concat.apply([], options);
    };

    const handleCloseModal = (e, reason) => {

        if(reason === "backdropClick") {
            return
        }

        setCorrectModal(false);
        setErrorModal(false);
        setEndModal(false);

    };

    const getScore = () => {
        if (!disableScores) {
            const errorAnswerValue = -10;

            if (numErrorsInSelection() !== 0) {
                return (errorAnswerValue * numErrorsInSelection())
            } else {
                return 20;
            }
        } else {
            return null;
        }
    };

    const handleAcceptCorrectModal = () => {
        const totalOfQuestions = content[currentPath].length;

        if (parseInt(dafoId.replace("dafo", "")) === totalOfQuestions) {
            setEndModal(true);
        } else {
            history.push(currentDafoData.nextScreen);
        }

        setOptionSelected([]);
        setMaximumReached(false);
        setCorrectModal(false);
    };

    const handleCloseEndModal = () => {
        if (currentDafoData.lastScreenStage) {
            //dispatch(completeStage());
            history.push(currentDafoData.nextScreen, {
                buttonTitle: "Espera al moderador", disableButton: true
            })
        } else history.push(currentDafoData.nextScreen)
        setEndModal(false);
    };

    const handleOnClickRanking = () => {
        if (optionSelected.length > 0) {
            alert("Comprueba el resultado de este ejercicio antes de ver el ranking. Puedes verlo si no tienes seleccionada ninguna opción.")
        } else {
            history.push("/jugador/ranking", {
                buttonTitle: "Volver",
                buttonAction: (() => history.goBack()).toString()
            })
        }
    }

    return (
        <Body onClickInfo={() => setInfoModal(true)} showInfoButton={true} onClickRanking={handleOnClickRanking}>
            <div style={styles.content}>
                {showIndexOfQuestion()}
                <h3 style={styles.title}>{currentDafoData.title}</h3>
                <div style={styles.dafoWrapper}>

                    {currentDafoData.products.map((item, productIndex) => {
                        return <Dafo
                            key={productIndex}
                            product={item}
                            onOptionSelected={handleSelectOption}
                            optionsSelected={optionSelected}
                            maximumReached={maximumReached}/>
                    })}

                </div>
                <div style={styles.buttonWrapper}>
                    <Button title={"Comprobar"} disabled={optionSelected.length < currentDafoData.minimumSelected}
                            onClick={handleOnNextScreen}/>
                </div>

                <Modal title={"¡Correcto!"} open={correctModal} onClose={handleAcceptCorrectModal}
                       actions={[{title: "Siguiente", onClick: handleAcceptCorrectModal}]}>
                    <h3>¡Enhorabuena! Respuestas correctas.<br/><br/> Continúa a la siguiente.</h3>
                </Modal>

                <Modal title={"¡Casi!"} open={errorModal} onClose={handleCloseModal}
                       actions={[{title: "Volver a intentar", onClick: handleCloseModal}]}>
                    <h3>Hay errores en tu selección. Revísalo e inténtalo de nuevo.</h3>
                    <h3>Ten cuidado con los intentos, ¡cada comprobación penaliza!</h3>
                </Modal>

                <Modal title={"Final de la experiencia"} open={endModal} onClose={handleCloseEndModal}
                       actions={[{title: "Ir al ránking", onClick: handleCloseEndModal}]}>
                    <div style={{display: "flex"}}>
                        <Lottie animationData={wellDone} style={{height: 250, width: 250}}/>
                        <div style={{marginTop: 34}}>
                            <h3>¡Enhorabuena, has completado la experiencia!</h3>
                            <h3>A continuación se te mostrará ránking final. Espera instrucciones del dinamizador.</h3>
                        </div>
                    </div>
                </Modal>

                <Modal title={"Escenario 6: DAFO"} open={initialModal} actions={[{
                    title: "CONTINUAR", onClick: () => {
                        setInitialModal(false);
                        setInfoModal(true)
                    }
                }]}>
                    <div>
                        <h4>En la sala anterior, hemos encontrado una carpeta, Parece ser que en ella hay información
                            estratégica tanto de Producto como de sus competidores, pero está desordenada y
                            mezclada.</h4>
                        <h4>Con esta información, se podrían crear los DAFO de los diferentes productos para ver qué nos
                            vamos a encontrar en la calle.</h4>
                    </div>
                </Modal>
                <Modal title={"Instrucciones"} open={infoModal}
                       actions={[{title: "Aceptar", onClick: () => setInfoModal(false)}]}>
                    <h4 style={{marginTop: 0}}>
                        Debéis construir los DAFO de los diferentes productos para el asma grave. Para ello, se os irán
                        presentando afirmaciones y dispondréis de cinco matrices DAFO (una para cada producto).</h4>
                    <h4>Para asociar una afirmación con el sector del DAFO adecuado, tendréis que clicar en dicho sector
                        (veréis que la casilla queda sombreada) y después, en “continuar”.</h4>
                    <h4>Si queréis anular una selección ya marcada solo tendréis que clicar de nuevo sobre la misma.</h4>
                    <h4 style={{fontWeight: 'bold'}}>Leyenda DAFO:</h4>
                    <ul>
                        <li>D - Debilidad (Atributo negativo, interno)</li>
                        <li>A - Amenaza (Atributo negativo, externo)</li>
                        <li>F - Fortaleza (Atributo positivo, Interno)</li>
                        <li>O - Oportunidad (Atributo positivo, Externo)</li>
                    </ul>
                    <h4>Si la respuesta es correcta, el sistema os permitirá continuar, sino, os informará que habéis
                        fallado y os penalizará. No se os permitirá avanzar hasta que no coloquéis correctamente todas
                        las afirmaciones.</h4>

                    <ul>
                        <li><strong>Objetivo:</strong> asociar correctamente cada afirmación con el producto y el sector
                            del DAFO adecuado.
                        </li>
                        <li><strong>Penalizaciones:</strong> envío de respuestas incorrectas.</li>
                        <li><strong>Puntuación:</strong>
                            <ul>
                                <li>Respuesta correcta &rarr; +20 puntos</li>
                                <li>Respuesta incorrecta &rarr; -10 puntos</li>
                            </ul>
                        </li>
                    </ul>

                    <h4>Recordad que disponéis de comodines en caso de necesitar ayuda.</h4>

                </Modal>

            </div>

        </Body>
    )
};

export default Stage6;
