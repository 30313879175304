import {SAVE_USER_AVATAR, SAVE_USER_TYPE} from "./actionType";

export const saveUserType = userType => {
    return {
        type: SAVE_USER_TYPE,
        payload: userType
    }
}

export const saveUserAvatarType = selectedAvatar => {
    return {
        type: SAVE_USER_AVATAR,
        payload: selectedAvatar
    }
}
