import React from 'react';

import {content} from "../../../data/scenes/content";
import TableInteractionsTag from "../../units/TableInteractionsTag";

import styles from "./styles";

const TableInteractions = ({onCellSelected, optionsPlaced, onRemoveClick, wrongAnswers, onColumnTitleClick}) => {
    const currentPath = "/jugador/escenario4";
    const rows = content[currentPath].rows;
    const columns = content[currentPath].columns;
    const cells = Array.from({length: 5});

    return (
        <div style={styles.container}>
            <table style={styles.table}>
                <div style={styles.tableContent}>
                    <tbody>
                    <tr>
                        <th></th>
                        {columns.map((item, index) => {
                            return <th key={index} style={styles.borderLeft}>
                                <div style={styles.tableHeader} onClick={() => onColumnTitleClick(item.id)}>
                                    <p style={styles.tableHeaderText}>{item.title}</p>
                                </div>
                            </th>
                        })}
                    </tr>
                    {rows.map((row, index) => {
                        return (
                            <tr key={index} style={index !== rows.length - 1 ? styles.borderBottom : {}}>
                                <td>
                                    <div style={styles.tableRowTitle}>
                                        <p style={styles.tableRowTitleText}>{row.title}</p>
                                    </div>
                                </td>
                                {cells.map((cell, cellIndex) => {
                                    const cellId = row.rowId + "-" + (cellIndex)
                                    const isCellSelected = optionsPlaced.find(item => item.cell === cellId)
                                    const isWrong = wrongAnswers.find(item => item.cell === cellId)
                                    return (
                                        <td key={cellIndex} style={styles.borderLeft} onClick={() => onCellSelected(cellId)}>
                                            {!!isCellSelected
                                                ? <div style={styles.tagWrapper}>
                                                    <TableInteractionsTag
                                                        tagText={isCellSelected.title}
                                                        onRemoveClick={(event) => onRemoveClick(event, isCellSelected.id)}
                                                        isWrong={!!isWrong}
                                                    />
                                                  </div>
                                                : <div style={styles.cell}/>}
                                        </td>)
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </div>
            </table>
        </div>
    )
};

export default TableInteractions;
