import QuestionGroups from "../../../components/composed/QuestionGroups";
import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {content} from "../../../data/scenes/content";
import {logic} from "../../../data/scenes/logic";
import Body from "../../../components/composed/Body/Body";
import Modal from "../../../components/composed/Modal/Modal";
import Colors from "../../../theme/colors";
import {completeStage} from "../../../store/actions/stagesType";
import {useDispatch} from "react-redux";
import styles from "../stage6/styles";

export const Stage5 = () => {

    const [initialModal, setInitialModal] = useState(true);
    const [infoModal, setInfoModal] = useState(false);
    const [endModal, setEndModal] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const escenario = 5;
    const {groupId} = useParams();
    const currentPath = `/jugador/escenario${escenario}`;
    let groupContent = content[currentPath].find(item => item.id === groupId);
    let groupLogic = logic[currentPath].find(item => item.id === groupId);
    let href = window.location.href;

    const handleFinishState = () => {
        if (groupContent.lastScreenStage) {
            setEndModal(true);
            dispatch(completeStage());
        } else {
            history.push(groupContent.nextScreen)
        }
    }

    const handleOnClickRanking = () => {
        alert("Completa este escenario antes de ver el ranking")
    }

    const showIndexOfQuestion = () => {
        const totalOfQuestions = content[currentPath].length;

        return (
            <div style={styles.questionIndex}>
                <p style={styles.questionIndexText}>{content[currentPath].findIndex(item => item.id === groupId) + 1}</p>
                <p style={styles.questionIndexText}> / </p>
                <p style={styles.questionIndexText}>{totalOfQuestions}</p>
            </div>
        )
    };

    return (
        <Body onClickInfo={() => setInfoModal(true)} showInfoButton={true} onClickRanking={handleOnClickRanking}>
            <div style={{overflowY: "auto", width: '100%'}}>
                {showIndexOfQuestion()}
                <QuestionGroups escenario={escenario} groupId={groupId} currentPath={currentPath}
                                groupContent={groupContent} handleFinishStage={handleFinishState}
                                groupLogic={groupLogic} href={href}/>
            </div>
            <Modal title={"Escenario 5: Construyendo respuestas"} open={initialModal} actions={[{
                title: "CONTINUAR", onClick: () => {
                    setInitialModal(false);
                    setInfoModal(true)
                }
            }]}>
                <div>
                    <h4>Finalmente hemos podido reconstruir la tabla de competidores, aquí tenemos información muy interesante. ¡Oh! Mirad se acerca de nuevo el técnico, ¿Qué querrá?</h4>
                    <div style={{
                        backgroundColor: Colors.artichoke,
                        padding: 12,
                        borderRadius: 6,
                        marginBottom: 8,
                        maxWidth: '80%'
                    }}>
                        <span style={{fontWeight: "bold"}}>Técnico</span>
                        <p>Debéis disculparme, pero antes se me olvidó preguntaros un par de cuestiones más acerca de
                            Producto, ¿podéis ayudarme?</p>
                    </div>
                </div>
            </Modal>
            <Modal title={"Instrucciones"} open={infoModal}
                   actions={[{title: "Aceptar", onClick: () => setInfoModal(false)}]}>
                <h4 style={{marginTop: 0}}>A continuación, se abordarán temas de interés acerca de Producto y tendréis
                    que construir los estudiojes clave sobre vuestro producto.</h4>
                <h4>En cada “topic“, podréis ver cuatro grupos de estudiojes con cuatro opciones por grupo.</h4>
                <h4>Si seleccionáis la opción correcta de cada grupo, construiréis un estudioje adecuado y coherente
                    acerca de Producto.</h4>
                <h4>Elegid la opción correcta de cada grupo y cuando tengáis las cuatro opciones seleccionadas, clicad
                    en “enviar respuestas”.</h4>
                <h4>Si habéis seleccionado las cuatro opciones correctas en un grupo, el sistema os permitirá continuar
                    al siguiente, sino, os informará que habéis fallado y os penalizará. No se os permitirá avanzar
                    hasta que no solucionéis los diferentes ‘’topics’’.</h4>

                <ul>
                    <li><strong>Objetivo:</strong> construir el estudioje correcto acerca de Producto.</li>
                    <li><strong>Penalizaciones:</strong> envío de respuestas incorrectas.</li>
                    <li><strong>Puntuación:</strong>
                        <ul>
                            <li>Respuesta correcta &rarr; +20 puntos</li>
                            <li>Respuesta incorrecta &rarr; -10 puntos</li>
                        </ul>
                    </li>
                </ul>

                <h4>Recordad que disponéis de comodines en caso de necesitar ayuda.</h4>

            </Modal>
            <Modal title={"¡Bien hecho!"} open={endModal} actions={[{
                title: "CONTINUAR", onClick: () => {
                    setEndModal(false);
                    history.push(groupContent.nextScreen, {
                        buttonTitle: "Continuar",
                        buttonActionPath: "/jugador/selector-escenarios"
                    })
                }
            }]}>
                <div style={{
                    backgroundColor: Colors.artichoke,
                    padding: 12,
                    borderRadius: 6,
                    marginBottom: 8,
                    maxWidth: '80%'
                }}>
                    <span style={{fontWeight: "bold"}}>Técnico</span>
                    <p>Muchísimas gracias, parece que sois verdaderos expertos en Producto. Veo que tenéis información
                        que revisar; aquí mismo hay un despacho que podéis utilizar sin problema. Gracias de nuevo.</p>
                </div>

            </Modal>
        </Body>
    )
}

export default Stage5;
