export const loginPlayerFormatter = (team_name, avatar, players) => {
    let newPlayers = players.join(", ");
    return {team_name, avatar, players: newPlayers};
}

export const rankingFormatterFromAPI = data => {
    return data.map(item => {
        return {
            groupName: item.name + " (" + item.total_score + "p.)",
            stage01: Number(item.score1),
            stage02: Number(item.score2),
            stage03: Number(item.score3),
            stage04: Number(item.score4),
            stage05: Number(item.score5),
            stage06: Number(item.score6)
        }
    });
};
