import React from "react";
import {UNIQUE_SELECTION_TYPES} from "../../../utils/constants";
import Colors from "../../../theme/colors";

// Components

const UniqueSelection = ({title, type, details, options}) => {

    if (type === UNIQUE_SELECTION_TYPES.LONG) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 21,
                marginBottom: 21,
                marginRight: 12,
                flex: 1
            }}>
                <div style={{
                    width: 4,
                    backgroundColor: Colors.paolaVeroneseGreen,
                    display: "flex",
                    marginRight: 18,
                    boxShadow: `0px 4px 4px ${Colors.paolaVeroneseGreen}`
                }}/>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    backgroundColor: '#151515A5',
                    padding: 40,
                    borderRadius: 16,
                    width: '100%'
                }}>
                    {(title === '') ? null : <h3 style={{color: "orange", marginTop: 0}}>{title}</h3>}

                    <div>
                        {details.map((option) =>
                            <p style={{fontSize: 14}}><strong>{option.title}: </strong>{option.description}</p>)
                        }
                    </div>

                    <div
                        style={{display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: 32}}>
                        {options.map( (option) =>

                            <div>{option}</div>)
                        }
                    </div>
                </div>
            </div>
        )

    } else if (type === UNIQUE_SELECTION_TYPES.SHORT) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 21,
                marginBottom: 21,
                marginRight: 12,
                flex: 1
            }}>
                <div style={{
                    width: 4,
                    backgroundColor: Colors.paolaVeroneseGreen,
                    display: "flex",
                    marginRight: 18,
                    boxShadow: `0px 4px 4px ${Colors.paolaVeroneseGreen}`
                }}/>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: '#151515A5',
                    padding: 40,
                    borderRadius: 16,
                    flex: 1
                }}>
                    {(title === '') ? null : <h3 style={{color: "orange", marginTop: 0}}>{title}</h3>}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginTop: 32,
                            width: '100%'
                        }}>
                        {options.map((option) =>

                            <div>{option}</div>)
                        }
                    </div>
                </div>
            </div>
        )
    }
};
export default UniqueSelection;
