import React from 'react';
import logoSanofi from '../../../assets/logo-sanofi.png';
import logoDupixent from '../../../assets/logo-dupixent.png';

import Header from "../Header/Header";
import {useSelector} from "react-redux";
import Sync from "../../functional/sync";
import {STAGE_STATUS} from "../../../utils/constants";

const Body = ({
                  children, withHeader = true, showCurrentScene = true, showPosition = true, showInfoButton = false,
                  onClickInfo = () => null, onClickRanking = function () {
        return null
    }, enablePolling = true
              }) => {

    const totalScore = useSelector((state) => state.logic.totalScore)

    const stages = useSelector((state) => state.logic.stagesStatus)
    const currentScene = stages ? stages.find(stage => stage.status === STAGE_STATUS.CURRENT).num : "-"

    return (
        <div style={{width: '100%', height: '100%', display: "flex", flex: 1, flexDirection: "column"}}>
            {enablePolling && <Sync/>}
            {withHeader &&
            <Header score={totalScore} position={"-"} currentScene={currentScene} showCurrentScene={showCurrentScene}
                    showPosition={showPosition} showInfoButton={showInfoButton} onClickInfo={onClickInfo}
                    onClickRanking={onClickRanking}/>}
            <div style={{
                margin: "0 auto",
                display: "flex",
                flex: 1,
                width: '100%',
                justifyContent: "center",
                maxWidth: 1380,
                padding: 24,
                boxSizing: "border-box",
                marginBottom: 100 - 44,
                marginTop: -24,
                overflow: "hidden"
            }}>
                {children}
            </div>

            <footer style={{
                position: "absolute",
                display: "flex",
                flex: 1,
                width: '100%',
                justifyContent: 'center',
                alignItems: "center",
                bottom: 0
            }}>

                <div style={{
                    display: 'flex', width: '100%', height: 80, paddingTop: 20, paddingBottom: 20, paddingLeft: 24,
                    paddingRight: 24, boxSizing: "border-box", maxWidth: 1380, justifyContent: 'center'
                }}>

                    <img src={logoSanofi} style={{height: 40}}/>
                    <div style={{flex: 1}}/>
                    <div style={{display: "flex", alignItems: "center", height: 40}}>
                        <img src={logoDupixent} style={{marginLeft: 22, height: 40}}/>
                    </div>
                </div>


            </footer>
        </div>
    )

}

export default Body;
