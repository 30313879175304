import colors from "../../../theme/colors";

export default {
    content: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        width: '100%',
        padding: '0 100px'
    },
    title: {
        fontSize: 22,
        textAlign: 'center',
        marginBlockStart: 0
    },
    dafoWrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: 76,
        flexWrap: 'wrap'
    },
    buttonWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    questionIndex: {
        display: 'flex',
        justifyContent: 'center'
    },
    questionIndexText: {
        fontSize: 16,
        fontWeight: 400,
        color: colors.grayWeb
    }
}
