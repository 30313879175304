import axios from 'axios';

const axiosService = axios.create({
    baseURL: "/api",
    responseType: "json",
    withCredentials: true,
    /*
        httpAgent: new https.Agent({
            rejectUnauthorized: false
        }),
    */
    headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
    },
});

axiosService.interceptors.response.use(null, async (error) => {

    if (error.response.status === 401) {
        console.error("Eror de token", error.response.data)
    }

    return Promise.reject(error);

});


export default axiosService;
