import React from 'react';
import {OptionSelect} from './optionSelect';
import {OptionDefault} from './optionDefault';

const Option = ({title, select, onClick, disabled}) => {
    if (select) {
        return (
            <OptionSelect onClick={onClick} disabled={disabled}>
                <span>{title}</span>
            </OptionSelect>
        )
    }
    return (
        <OptionDefault onClick={onClick} disabled={disabled}>
            <span>{title}</span>
        </OptionDefault>
    )
};

export default Option
