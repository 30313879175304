//USER

export const SAVE_USER_TYPE = "SAVE_USER_TYPE";

export const SAVE_USER_AVATAR = "SAVE_USER_AVATAR";

export const QUESTION_GROUPS_TYPE = "QUESTION_GROUPS_TYPE";

export const SAVE_SCENE_AND_TOTAL_SCORE = "SAVE_SCENE_SCORE";

export const SET_GAME_STATUS = "SET_GAME_STATUS";

export const SET_ROOM_SCORES_STATUS = "SET_ROOM_SCORES_STATUS";

export const COMPLETE_STAGE = "COMPLETE_STAGE";
