import React, {useEffect, useState} from "react";
import Colors from "../../../theme/colors";
import Input from "../../../components/units/Input/Input";
import {AVATARS, BUTTON_TYPES} from "../../../utils/constants";
import Logo from "../../../assets/logo.png"
import Body from "../../../components/composed/Body/Body";
import Button from "../../../components/units/Button/Button";
import {useHistory} from "react-router-dom";
import {loginPlayerService} from "../../../data/services/programServices";
import {saveUserAvatarType} from "../../../store/actions/saveUserType";
import {useDispatch} from "react-redux";

const Register = () => {

    const history = useHistory();
    const [teamName, setTeamName] = useState('');
    const [avatarSelected, setAvatarSelected] = useState(null);
    const [players, setPlayers] = useState(["", "", "", "", "", ""]);
    const [disableNextScreen, setdisableNextScreen] = useState(true);

    const dispatch = useDispatch();

    useEffect(() => {
        if (avatarSelected && teamName !== '' && players.some(item => item !== "")) {
            setdisableNextScreen(false);
        } else {
            setdisableNextScreen(true);
        }
    }, [players, avatarSelected, teamName])

    const handleTeamName = (e) => {
        setTeamName(e.target.value)
    };
    const handleAvatar = (avatar) => {
        setAvatarSelected(avatar)
    };
    const handleAddPlayer = () => {
        players.push("");
        let newArr = [...players];
        setPlayers(newArr)
    };

    const handlePlayerName = (event, index) => {
        let newArr = [...players];
        newArr[index] = event.target.value;
        setPlayers(newArr);
    };

    const handleNextScreen = () => {
        loginPlayerService(teamName, avatarSelected, players)
            .then(r => {
                if (r.res === true) {
                    dispatch(saveUserAvatarType(avatarSelected));
                    history.push("/registro/espera");
                }
            })
            .catch(e => {
                alert(e);
            })

    }

    const AvatarCard = ({image, id}) => {
        if (avatarSelected === null || avatarSelected === id) {
            return (
                <button style={{
                    border: "none", backgroundColor: 'black', margin: 4, cursor: "pointer", height: 50,
                    width: 50, borderRadius: 8, padding: 4, alignItems: "center", justifyContent: "center"
                }}
                        onClick={() => handleAvatar(id)}>
                    <img src={image} style={{objectFit: 'contain', height: '100%', width: '100%'}}/>
                </button>
            )
        } else {
            return (
                <button style={{
                    border: "none", opacity: 0.5, backgroundColor: 'black', margin: 4, cursor: "pointer",
                    height: 50, width: 50, borderRadius: 8, padding: 4, alignItems: "center", justifyContent: "center"
                }}
                        onClick={() => handleAvatar(id)}>
                    <img src={image} style={{objectFit: 'contain', height: '100%', width: '100%'}}/>
                </button>
            )
        }
    };

    return (
        <Body withHeader={false} enablePolling={false}>
            <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <div style={{padding: 30, paddingBottom: 0, position: "relative", overflowY: "auto", maxWidth: 974}}>
                    <img src={Logo} style={{height: 100, width: "auto", position: "absolute", left: -20, top: 10}}/>
                    <div style={{display: "flex", flexDirection: "row", width: '100%', marginBottom: 12}}>
                        <div style={{
                            width: 4,
                            backgroundColor: Colors.paolaVeroneseGreen,
                            display: "flex",
                            marginRight: 18,
                            boxShadow: `0px 4px 4px ${Colors.paolaVeroneseGreen}`
                        }}/>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            backgroundColor: '#151515A5',
                            padding: 30,
                            borderRadius: 16,
                            width: '100%',
                            alignItems: "center"
                        }}>
                            <h1>REGISTREMOS EL EQUIPO</h1>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <div style={{marginRight: 100}}>
                                    <h3>NOMBRE DEL EQUIPO</h3>
                                    <Input customStyle={{width: '100%'}} placeholder={"Insertar nombre"}
                                           value={teamName}
                                           onChange={(event) => handleTeamName(event)}/>
                                    <h3>AVATAR</h3>
                                    <div style={{width: 300, flexWrap: "wrap", height: 200}}>
                                        {AVATARS.map(avatar => {
                                            return <AvatarCard image={avatar.avatar} id={avatar.id}/>
                                        })}
                                    </div>
                                </div>
                                <div>
                                    <h3 style={{marginLeft: 12}}>NOMBRE DE LOS INTEGRANTES</h3>
                                    <div>
                                        <div style={{
                                            width: '100%',
                                            display: "flex",
                                            flexWrap: "wrap",
                                            overflowY: "auto",
                                            height: 200,
                                            maxWidth: 850,
                                            justifyContent: "flex-start"
                                        }}>
                                            {players.map((player, index) => {
                                                return (
                                                    <div style={{flex: 1, marginLeft: 12, marginTop: 14}}>
                                                        <Input customStyle={{
                                                            minWidth: 180,
                                                            width: '100%',
                                                            boxSizing: "border-box"
                                                        }} placeholder={"Insertar nombre"} value={player}
                                                               onChange={(event) => handlePlayerName(event, index)}/>
                                                    </div>
                                                )
                                            })}
                                            <div style={{marginLeft: 12, flex: 1, minWidth: 180}}/>
                                        </div>

                                        <div style={{
                                            width: '100%',
                                            justifyContent: "center",
                                            display: "flex",
                                            marginTop: 30
                                        }}>
                                            <Button type={BUTTON_TYPES.SECONDARY} title={'AÑADIR MIEMBRO'}
                                                    onClick={handleAddPlayer}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button disabled={disableNextScreen} type={BUTTON_TYPES.PRIMARY} title={'¡ESTAMOS LISTOS!'}
                        onClick={handleNextScreen}/>
            </div>
        </Body>

    )
}

export default Register
