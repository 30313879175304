import React, {useEffect} from 'react';

import {useHistory} from "react-router-dom";
import Body from "../../../components/composed/Body/Body";
import logo from "../../../assets/logo.png";
import Colors from "../../../theme/colors";
import {getGameService} from "../../../data/services/programServices";
import {setGameStatusType} from "../../../store/actions/setGameStatus";
import {useDispatch} from "react-redux";

const Espera = ({}) => {

	const history = useHistory();
	const dispatch = useDispatch();

	let polling = null;

	useEffect(() => {
		checkGameStatus();
		if (!polling) {
			polling = setInterval(() => checkGameStatus(), 1000);
		}

		return function cleanup() {
			clearInterval(polling);
		}

	}, [])

	const checkGameStatus = () => {
		getGameService().then(r => {
			let is_playing = parseInt(r.res.is_playing) === 1;
			if (is_playing) {
				dispatch(setGameStatusType({gameActive: is_playing, gameHasStarted: true, gameEndTime: r.res.end_time}))
				history.push("/jugador/selector-escenarios");
			}
		})
	}

	return (
		<Body withHeader={false} enablePolling={false}>

			<div style={{
				display: "flex",
				flexDirection: "column",
				position: "relative",
				overflowY: "auto",
				flex: 1,
				justifyContent: "center",
				alignItems: "center"
			}}>
				<img src={logo} style={{height: 180}}/>

				<div style={{
					display: "flex",
					flexDirection: "column",
					backgroundColor: '#151515A5',
					marginTop: 40,
					padding: 12,
					borderRadius: 16,
					marginBottom: 40,
					alignItems: "center"
				}}>
					<div style={{
						display: "flex",
						flexDirection: "column",
						borderRadius: 16,
						alignItems: "center",
						padding: 20,
						minWidth: 676,
						border: `3px solid ${Colors.marigold}`,
						boxShadow: `0px 0px 6px ${Colors.marigold}`,
					}}>
						<p>ESPERA A QUE EL DINAMIZADOR EMPIECE EL JUEGO.</p>
						<p>Empezará la experiencia automáticamente.</p>
					</div>
				</div>

			</div>

		</Body>
	)
}

export default Espera;
