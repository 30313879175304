import {SAVE_SCENE_AND_TOTAL_SCORE} from "./actionType";

export const saveSceneScoreType = (score, scene) => {
    return {
        type: SAVE_SCENE_AND_TOTAL_SCORE,
        payload: {
            score,
            scene
        }
    }
}
