import React, {useEffect, useState} from 'react';
import UniqueSelection from "../../../components/composed/UniqueSelection/UniqueSelection";
import Option from "../../../components/units/Option/option";
import {BUTTON_TYPES} from "../../../utils/constants";
import Button from "../../../components/units/Button/Button";
import Modal from "../../../components/composed/Modal/Modal";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import {questionGroupsType} from "../../../store/actions/questionGroupsType";
import {saveSceneScoreType} from "../../../store/actions/saveSceneScore";
import Colors from "../../../theme/colors";

const QuestionGroups = ({
                            showNumErrors = true, escenario, groupContent, groupLogic, currentPath, groupId, href,
                            deselectWrong = false, blockCorrect = false, autonumberQuestions = false,
                            handleFinishStage
                        }) => {

    let answers = [];
    const dispatch = useDispatch();
    const history = useHistory();

    const [selectedOptions, setSelectedOptions] = useState(answers);
    const [disableNextButton, setDisableNextButton] = useState(true);
    const [score, setScore] = useState(groupContent.QUESTIONS.length * groupLogic.correctAnswer);
    const [openModal, setOpenModal] = useState({open: false, errors: null});
    const [finalPhrase, setFinalPhrase] = useState(null);
    const [errors, setErrors] = useState(0);

    const [correctSelectionIndexes, setCorrectSelectionIds] = useState([]);

    useEffect(() => {
        if (!selectedOptions.some(item => item === null)) {
            setDisableNextButton(false);
        }
    }, [selectedOptions])

    useEffect(() => {
        setSelectedOptions(answers);
        setDisableNextButton(true);
        setScore(groupContent.QUESTIONS.length * groupLogic.correctAnswer);
        setFinalPhrase(null);
        setErrors(0);
        setCorrectSelectionIds([]);
        document.getElementById("gorups-content").scrollTo(0, 0)
    }, [href])

    for (let i = 0; i < groupContent.QUESTIONS.length; i++) {
        answers.push(null)
    }

    const handleCloseModal = () => {
        if (openModal.errors) {
            setOpenModal({open: false, errors: openModal.errors});
        } else {
            setOpenModal({open: false, errors: null});
            dispatch(questionGroupsType(score, groupId, errors, currentPath))
            dispatch(saveSceneScoreType(score, "escenario" + escenario))
            handleFinishStage()
        }
    }

    const handleSelectedOption = (selected, question) => {
        if (blockCorrect && correctSelectionIndexes.length > 0) {
            if (correctSelectionIndexes.some(item => item === question)) {
                return;
            }
        }
        let newArr = [...selectedOptions];
        newArr[question] = selected;
        setSelectedOptions(newArr);
        setFinalPhrase(generateFinalPhrase(newArr))
    };

    const generateFinalPhrase = (options) => {
        let newPhrase = "";
        options.map(optionId => {
            for (let i = 0; i < groupContent.QUESTIONS.length; i++) {
                let option = groupContent.QUESTIONS[i].options.find(item => item.id === optionId);
                if (option) {
                    newPhrase += " " + option.description;
                }
            }
            if (optionId === null) {
                newPhrase += " (___) "
            }
        })
        return newPhrase;
    };

    const handleNextScreen = () => {
        let wrongAnswers = 0;

        let correct = [];
        let newSelectedOptions = [];
        selectedOptions.map((element, index) => {
            if (!groupLogic.answers.some(item => item === element)) {
                wrongAnswers += 1
                newSelectedOptions[index] = null;
            } else {
                correct.push(index);
                newSelectedOptions[index] = element;
            }
        })
        if (blockCorrect) setCorrectSelectionIds(correct);

        if (deselectWrong) {
            setSelectedOptions(newSelectedOptions);
            setDisableNextButton(true);
        }

        setErrors(prevState => prevState + wrongAnswers)

        let sceneScore = score;

        if (wrongAnswers === 0) {
            sceneScore = ((errors) * groupLogic.wrongAnswer + groupLogic.correctAnswer * selectedOptions.length);
            setScore(sceneScore);
            setOpenModal({open: true, errors: wrongAnswers});
        } else {
            setOpenModal({open: true, errors: wrongAnswers});
        }
    };

    const QuestionCard = ({item, question}) => {
        return (
            <UniqueSelection
                details={item.options}
                options={item.options.map((option) => {
                    return <Option title={option.title}
                                   disabled={blockCorrect && correctSelectionIndexes.some(item => item === question)}
                                   onClick={() => handleSelectedOption(option.id, question)}
                                   select={(selectedOptions[question] === option.id)}
                    />
                })}
                title={autonumberQuestions ? question + 1 + ": " + item.title : item.title}
                type={item.type}
            />
        )
    };

    return (
        <div style={{display: "flex", flex: 1, flexDirection: "column", alignItems: "center"}}>
            <div style={{margin: '0 auto', textAlign: "center"}}>
                <h3><i>{groupContent.title}</i></h3>
                {(groupContent.showFinalPhrase) ? <p>{finalPhrase}</p> : null}
            </div>
            <div id="gorups-content" style={{
                overflowY: "auto",
                overflowX: "visible",
                maxWidth: 990,
                width: '100%',
                marginBottom: 20,
                display: "flex",
                flexDirection: "column",
            }}>
                {groupContent.QUESTIONS.map((card, index) => <QuestionCard item={card} question={index}/>)}
                <div style={{width: '100%', textAlign: 'center'}}>
                    <Button type={BUTTON_TYPES.PRIMARY} title={"Siguiente"} disabled={disableNextButton}
                            onClick={() => handleNextScreen()}/>
                </div>
            </div>
            <Modal open={openModal.open}
                   onClose={handleCloseModal}
                   actions={[{
                       title: (openModal.errors) ? "Volver a intentar" : "Siguiente", onClick: handleCloseModal
                   }]} title={(openModal.errors) ? 'Selección incorrecta' : '¡Correcto!'}
            >

                {(openModal.errors) ?
                    <div>
                        {showNumErrors &&
                        <h3>Hay {openModal.errors} {(openModal.errors === 1) ? 'opción incorrecta' : 'opciones incorrectas'},
                            sigue intentándolo.</h3>}
                        <h3 style={{color: Colors.orangeRedCrayola}}>Has perdido {openModal.errors * Math.abs(groupLogic.wrongAnswer)} puntos.</h3>
                    </div> :
                    <h3>Vamos a la siguiente pantalla</h3>
                }

            </Modal>

        </div>
    )
};

export default QuestionGroups
