import colors from '../../../theme/colors';

export default {
    content: {
        borderRadius: 4,
        width: '33%',
        padding: 10,
        margin: 6,
        cursor: 'pointer',
        boxSizing: 'border-box'
    },
    text: {
        marginBlockStart: 0,
        marginBlockEnd: 0,
        fontSize: 12,
        textAlign: 'center'
    }
}
