import React, {useState} from 'react';

import {useDispatch, useSelector} from "react-redux";

import Body from "../../../components/composed/Body/Body";
import TableInteractions from "../../../components/composed/TableInteractions";
import Button from "../../../components/units/Button/Button";
import Modal from "../../../components/composed/Modal/Modal";
import ModalTag from "../../../components/units/ModalTag";

import {content} from "../../../data/scenes/content";

import styles from "./styles";
import {saveSceneScoreType} from "../../../store/actions/saveSceneScore";
import Colors from "../../../theme/colors";
import {completeStage} from "../../../store/actions/stagesType";

const Stage4 = ({history, location}) => {


    const currentPath = location.pathname;
    const screenContent = content[currentPath];
    const logic = useSelector((state) => state.logic[currentPath]);
    const numberOfRows = screenContent.rows.length;
    const numberOfColumns = screenContent.columns.length;
    const numberOfCells = numberOfRows * numberOfColumns;
    const initialScore = 500;
    const errorAnswerValue = logic.wrongAnswer;
    const dispatch = useDispatch();
    const nextScreen = screenContent.nextScreen;

    const [initialModal, setInitialModal] = useState(true);
    const [infoModal, setInfoModal] = useState(false);
    const [endModal, setEndModal] = useState(false);
    const [cellSelected, setCellSelected] = useState(null);
    const [openOptionsModal, setOpenOptionsModal] = useState(false);
    const [openModalPreviewOption, setOpenModalPreviewOption] = useState(false);
    const [modalOptionSelected, setModalOptionSelected] = useState("");
    const sanofiTag = screenContent.OPTIONS.find(op => op.id === '21');
    const astraZenecaTag = screenContent.OPTIONS.find(op => op.id === '05');
    const GSKTag = screenContent.OPTIONS.find(op => op.id === '35');
    const novartisTag = screenContent.OPTIONS.find(op => op.id === '58');
    const astraZenecaAmigenTag = screenContent.OPTIONS.find(op => op.id === '53');
    const [arrayWithModalOptionsSelected, setArrayWithModalOptionsSelected] = useState([
        {id: sanofiTag.id, title: sanofiTag.title, cell: '0-0'},
        {id: astraZenecaTag.id, title: astraZenecaTag.title, cell: '0-1'},
        {id: GSKTag.id, title: GSKTag.title, cell: '0-2'},
        {id: novartisTag.id, title: novartisTag.title, cell: '0-3'},
        {id: astraZenecaAmigenTag.id, title: astraZenecaAmigenTag.title, cell: '0-4'},
    ]);
    const [previewTag, setPreviewTag] = useState("");
    const [correctModal, setCorrectModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [wrongAnswers, setWrongAnswers] = useState([]);
    const [totalScore, setTotalScore] = useState(initialScore);
    const [numOfValidations, setNumOfValidations] = useState(0);
    const [numOfClicksInColumn1, setNumOfClicksInColumn1] = useState(0);
    const [numOfClicksInColumn2, setNumOfClicksInColumn2] = useState(0);

    const handleOnCellSelected = cellId => {
        const isThisCellAlreadySelected = arrayWithModalOptionsSelected.find(item => item.cell === cellId)
        setCellSelected(cellId)

        if(!!isThisCellAlreadySelected) {
            setPreviewTag(isThisCellAlreadySelected.title)
            setOpenModalPreviewOption(true);
        } else {
            setOpenOptionsModal(true);
        }
    };

    const handleOnCloseModal = () => {
        setOpenOptionsModal(false);
        setOpenModalPreviewOption(false);
        setErrorModal(false);
    };

    const handleSelectModalTag = tagId => {
        const isModalTagDisable = arrayWithModalOptionsSelected.find(item => item.id === tagId);

        if(modalOptionSelected === tagId) {
            setModalOptionSelected("")
        } else if (!!isModalTagDisable) {
            return null;
        } else {
            setModalOptionSelected(tagId)
        }
    };

    const handlePutModalTagInTable = () => {
        const modalTag = screenContent.OPTIONS.find(item => item.id === modalOptionSelected);
        const arrayModalOptionsSelected = [...arrayWithModalOptionsSelected];

        arrayModalOptionsSelected.push({id: modalTag.id, title: modalTag.title, cell: cellSelected});
        setArrayWithModalOptionsSelected(arrayModalOptionsSelected);
        setOpenOptionsModal(false);
        setModalOptionSelected("");
    };

    const handleOnRemoveTagClick = (event, modalTagId) => {
        event.stopPropagation();
        const newArrayWithModalOptionsSelected = [...arrayWithModalOptionsSelected];
        const newWrongAnswers = [...wrongAnswers];

        if (modalTagId === '21' || modalTagId === '05' || modalTagId === '35' || modalTagId === '58' || modalTagId === '53') {
            //Disable remove for first items in row
            return;
        }

        const indexOfTagToRemove = newArrayWithModalOptionsSelected.findIndex(item => item.id === modalTagId);
        newArrayWithModalOptionsSelected.splice(indexOfTagToRemove, 1);
        setArrayWithModalOptionsSelected(newArrayWithModalOptionsSelected);

        const isThisTagWrong = newWrongAnswers.find(item => item.id === modalTagId)

        if (!!isThisTagWrong) {
            const indexOfAnswerTagToRemove = newWrongAnswers.findIndex(item => item.id === modalTagId);
            newWrongAnswers.splice(indexOfAnswerTagToRemove, 1);

            setWrongAnswers(newWrongAnswers);
        }
    };

    const handleValidateResult = () => {
        if(numOfValidations !== 0 && numOfClicksInColumn1 >= 2 && numOfClicksInColumn2 >= 2) {
            history.push(nextScreen);
        } else {
            const errors = [];

            for(let i = 0; i < arrayWithModalOptionsSelected.length; i++) {
                const position = (arrayWithModalOptionsSelected[i].cell).split("-")

                if(logic.elementPositions[position[0]][position[1]].length > 1) {
                    const isCorrectOptionSelected = logic.elementPositions[position[0]][position[1]].some(item => item === arrayWithModalOptionsSelected[i].id)

                    if(!isCorrectOptionSelected) {
                        errors.push(arrayWithModalOptionsSelected[i])
                    }

                } else {

                    if(logic.elementPositions[position[0]][position[1]][0] !== arrayWithModalOptionsSelected[i].id) {
                        errors.push(arrayWithModalOptionsSelected[i])
                    }
                }
            }

            if(errors.length > 0) {
                setWrongAnswers(errors);
                setErrorModal(true);
            } else {
                setCorrectModal(true);
            }

            getScore(errors);
            setNumOfValidations(numOfValidations + 1);
        }
    };

    const getScore = errors => {
        const updatedScore = totalScore;
        const numberOfErrors = errors.length;

        if (numberOfErrors !== 0) {
            setTotalScore(updatedScore + (errorAnswerValue * numberOfErrors));
        } else {
            setTotalScore(updatedScore);
        }
    };

    const handleAcceptCorrectModal = () => {
        dispatch(saveSceneScoreType(totalScore, "escenario4"))
        setCorrectModal(false);
        setEndModal(true);
        dispatch(completeStage());
    };

    const handleOnColumnTitleClick = columnId => {
        if(numOfValidations !== 0) {
            switch(columnId) {
                case "01":
                    setNumOfClicksInColumn1(numOfClicksInColumn1 + 1);
                    break;
                case "02":
                    setNumOfClicksInColumn2(numOfClicksInColumn2 + 1);
                    break;
                default:
                    return null;
            }
        }
    };

    const checkIfButtonIsDisable = () => {
        if (numOfValidations !== 0 && numOfClicksInColumn1 >= 2 && numOfClicksInColumn2 >= 2) {
            return false;
        } else return arrayWithModalOptionsSelected.length !== numberOfCells;
    };

    const handleOnClickRanking = () => {
        if (arrayWithModalOptionsSelected.length > 5) {
            alert("Completa este escenario antes de ver el ranking")
        } else {
            history.push("/jugador/ranking", {
                buttonTitle: "Volver",
                buttonAction: (() => history.goBack()).toString()
            })
        }
    }

    return (
        <Body onClickInfo={() => setInfoModal(true)} showInfoButton={true} onClickRanking={handleOnClickRanking}>

            <div style={styles.content}>
                <TableInteractions
                    onCellSelected={handleOnCellSelected}
                    optionsPlaced={arrayWithModalOptionsSelected}
                    onRemoveClick={handleOnRemoveTagClick}
                    wrongAnswers={wrongAnswers}
                    onColumnTitleClick={handleOnColumnTitleClick}
                />

                <div style={styles.buttonWrapper}>
                    <Button title={"Siguiente"} disabled={false} onClick={handleValidateResult}/>
                </div>
            </div>

            <Modal title={"SELECCIONA LA CORRECTA"}
                   open={openOptionsModal} onClose={handleOnCloseModal} hideClose={false}
                   actions={[{title: "COLOCAR", onClick: handlePutModalTagInTable}]}
                   buttonDisabled={modalOptionSelected === ""}>
                <div style={{overflowY: 'auto', maxHeight: 400}}>
                    <div style={styles.modalWithTagsContainer}>
                        {screenContent.OPTIONS.map((option, index) => {
                            const isOptionAlreadySelected = arrayWithModalOptionsSelected.find(item => item.id === option.id)
                            return (
                                <ModalTag
                                    key={index}
                                    title={option.title}
                                    onClick={() => handleSelectModalTag(option.id)}
                                    isCurrentOptionSelected={option.id === modalOptionSelected}
                                    isOptionDisable={!!isOptionAlreadySelected}
                                />)
                        })}
                    </div>
                </div>
            </Modal>

            <Modal title={""} open={openModalPreviewOption} onClose={handleOnCloseModal}
                   actions={[{title: "Cerrar", onClick: handleOnCloseModal}]}>
                <h3 style={styles.previewTag} dangerouslySetInnerHTML={{__html: previewTag}}/>
            </Modal>

            <Modal title={"¡Correcto!"} open={correctModal} onClose={handleAcceptCorrectModal}
                   actions={[{title: "Siguiente", onClick: handleAcceptCorrectModal}]}>
                <h3>¡Enhorabuena! Respuestas correctas.<br/><br/> Continúa a la siguiente.</h3>
                <h3 style={{color: Colors.mantis}}>Tienes {totalScore} puntos</h3>
            </Modal>

            <Modal title={"¡Casi!"} open={errorModal} onClose={handleOnCloseModal}
                   actions={[{title: "Volver a intentar", onClick: handleOnCloseModal}]}>
                <h3>Hay {wrongAnswers.length} etiquetas mal colocadas. Revísalo e inténtalo de nuevo.</h3>
                <h3>Ten cuidado con los intentos, ¡cada comprobación penaliza!</h3>
                <h3 style={{color: Colors.orangeRedCrayola}}>Tienes {totalScore} puntos</h3>
            </Modal>

            <Modal title={"Escenario 4: Tabla de competidores"} open={initialModal} actions={[{
                title: "CONTINUAR", onClick: () => {
                    setInitialModal(false);
                    setInfoModal(true)
                }
            }]}>
                <div>
                    <h4>Perfecto, acaba de llegar la directora, a ver que puede decirnos.</h4>
                    <div style={{
                        backgroundColor: Colors.artichoke,
                        padding: 12,
                        borderRadius: 6,
                        marginBottom: 8,
                        maxWidth: '80%'
                    }}>
                        <span style={{fontWeight: "bold"}}>Directora</span>
                        <p>Hola, veo que sois de Laboratorio. Soy la directora del departamento y en su momento me
                            encargué
                            de revisar los dosieres sobre los biológicos para el asma grave. En su día, creamos una
                            tabla comparativa con los cinco biológicos para el asma grave que estaban en el mercado y en
                            proceso de lanzamiento. Esa información también se ha perdido con el incendio… ¡Qué faena!.
                            Sin embargo, me han dicho que queda una copia impresa de esa tabla en la sala de gestión de
                            residuos. ¡Daos prisa si no queréis que la destruyan!
                        </p>
                    </div>
                    <div style={{
                        backgroundColor: Colors.artichoke,
                        padding: 12,
                        borderRadius: 6,
                        marginBottom: 8,
                        maxWidth: '80%'
                    }}>
                        <span style={{fontWeight: "bold"}}>Equipo de Laboratorio</span>
                        <p>Vaya, parece que no hemos llegado a tiempo, acaban de meter la tabla en la trituradora, pero
                            ¡un segundo!, quizás podamos recomponerla.
                        </p>
                    </div>
                </div>
            </Modal>
            <Modal title={"Instrucciones"} open={infoModal}
                   actions={[{title: "Aceptar", onClick: () => setInfoModal(false)}]}>
                <h4 style={{marginTop: 0}}>
                    A continuación, se os mostrará una tabla matriz con los principales competidores de Producto en
                    el mercado (columnas) y aquellos atributos más importantes de cada producto (filas).</h4>
                <h4>La tabla aparecerá en blanco, sin ninguna información.</h4>
                <h4>Vuestro objetivo consistirá en completar este rompecabezas:</h4>
                <ol>
                    <li>Clicad sobre la casilla en blanco que quer&aacute;is cumplimentar.</li>
                    <li>Se desplegar&aacute; un listado con todas las &lsquo;&rsquo;piezas del puzle&rsquo;&rsquo; y
                        solo una de ellas ser&aacute; la adecuada para esa casilla.
                    </li>
                    <li>Clicad sobre el estudioje que quer&aacute;is pegar en la casilla.</li>
                    <li>Una vez el estudioje est&eacute; seleccionado, clicad en &lsquo;&rsquo;colocar&rsquo;&rsquo;.
                    </li>
                </ol>
                <h4>Si colocáis alguna pieza por error, podréis borrarla clicando sobre la cruz situada a la derecha de
                    la casilla.
                    Para poder visualizar el texto que habéis colocado en una casilla, tenéis que clicar en encima.</h4>
                <h4>Si la tabla se ha cumplimentado de forma correcta, el sistema os permitirá continuar, y sino, os
                    informará
                    que habéis fallado (las casillas erróneas se sombrearán en rojo) y os penalizará.
                    No se os permitirá avanzar hasta que no la completéis adecuadamente.</h4>

                <ul>
                    <li><strong>Objetivo:</strong> construir correctamente la tabla de competidores.
                    </li>
                    <li><strong>Penalizaciones:</strong> envío de respuestas incorrectas.</li>
                    <li><strong>Puntuación:</strong>
                        <ul>
                            <li>Respuesta correcta &rarr; +500 puntos</li>
                            <li>Respuesta incorrecta &rarr; -50 puntos</li>
                        </ul>
                    </li>
                </ul>

                <h4>Recordad que disponéis de comodines en caso de necesitar ayuda.</h4>

            </Modal>
            <Modal title={"¡Bien hecho!"} open={endModal} actions={[{
                title: "CONTINUAR", onClick: () => {
                    setEndModal(false);
                    history.push(screenContent.nextScreen, {
                        buttonTitle: "Continuar",
                        buttonActionPath: "/jugador/selector-escenarios"
                    })
                }
            }]}>
                <div style={{
                    backgroundColor: Colors.artichoke,
                    padding: 12,
                    borderRadius: 6,
                    marginBottom: 8,
                    maxWidth: '80%'
                }}>
                    <span style={{fontWeight: "bold"}}>Equipo de Laboratorio</span>
                    <p>Nos ha costado mucho trabajo, pero finalmente lo hemos conseguido. Disponemos de toda la
                        información de los productos para el asma; podemos volver con la directora. Además, mirad, hemos
                        localizado esta carpeta con información interesante de todos los productos; vamos a echarle un
                        vistazo en otra parte.</p>
                </div>

            </Modal>

        </Body>
    )
};

export default Stage4;
