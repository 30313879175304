export const style = {
    doughnut: {
        borderRadius: '100px',
        height: '56px',
        width: '56px' ,
        minWidth: '56px',
    },

    innerCircle: {
        height: '42px',
        width: '42px' ,
        minWidth: '42px',
        minHeight: '42px',
        borderRadius: '50%',
    },
};
