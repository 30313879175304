import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import store from "./store/store";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register(
    {
        onUpdate: registration => {
            var r = window.confirm('Hay una actualización disponible. Al pulsar "aceptar" se actualizará.');
            if (r === true) {
                if (registration && registration.waiting) {
                    registration.waiting.postMessage({type: 'SKIP_WAITING'});
                }
                window.location.reload();
                window.location.href = '/';
            }
        },
    },
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
