import styled from "styled-components";
import {Button as MuiButton} from "@material-ui/core"

export const OptionDefault = styled(MuiButton)`
  padding: 8px;
  background: transparent;
  border: 2px solid var(--sheenGreen);
  min-height: 48px;
  min-width: 150px;
  border-radius: 0;
  color: var(--sheenGreen) !important;
  font-size: 16px;
  font-family: Aller;
  transform: skew(-40deg);

  span {
    transform: skew(23deg);
  }

  :hover {
    background: var(--sheenGreen);
    color: white !important;
  }

`
