import React from "react";
import {Modal as MuiModal} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from "../../units/Button/Button";
import Colors from "../../../theme/colors";


const Modal = ({open = false, title, children, actions, onClose, buttonDisabled = false, hideClose = true}) => {

    return (

        <MuiModal
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 300,
            }}
        >
            <Fade in={open}>
                <div style={{
                    display: "flex",
                    flex: 1,
                    height: "100%",
                    justifyContent: "center",
                    alignItems: "center"
                }}>

                    <div style={{
                        backgroundColor: Colors.jet,
                        width: "60%",
                        padding: '12px 36px 12px 36px',
                        border: `16px solid ${Colors.dimGray}`,
                        borderTopLeftRadius: 48,
                        borderBottomRightRadius: 48,
                        maxHeight: '88%',
                        overflowY: "auto"
                    }}>
                        <div style={{display: 'flex', justifyContent: "space-between"}}>
                            <div id="transition-modal-title"
                                 dangerouslySetInnerHTML={{__html: `<h1>${title}</h1>`}}/>
                            <span style={{
                                width: 16,
                                height: 28,
                                cursor: "pointer",
                                fontWeight: 'bold',
                                display: hideClose ? "none" : "block"
                            }}
                                  onClick={onClose}>X</span>
                        </div>

                        {children}

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            marginTop: 12
                        }}>
                            {actions.map((action, index) =>
                                <Button key={index} title={action.title} onClick={action.onClick}
                                        disabled={buttonDisabled}/>
                            )}
                        </div>

                    </div>
                </div>
            </Fade>
        </MuiModal>
    )


};
export default Modal;
