import colors from "../../../theme/colors";

export default {
    content: {
        width: 174,
        height: 202,
        backgroundColor: colors.grayWebOpacity,
        borderRadius: 15,
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
        paddingBottom: 24,
        margin: '12px 0'
    },
    name: {
        fontSize: 18,
        fontWeight: 400,
        textAlign: 'center'
    },
    optionsLayout: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    }
}
