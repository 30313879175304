import React, {useEffect, useState} from 'react';
import logo from "../../../assets/logo.png"
import Colors from "../../../theme/colors";
import {useSelector} from "react-redux";
import {HeaderButton} from "./styles";
import infoDefault from "../../../assets/info_outline_default.png";
import rankingDefault from "../../../assets/ranking_outline_default.png";
import {AVATARS} from "../../../utils/constants";
import {useHistory, useLocation} from "react-router-dom";

const Header = ({
                    score,
                    currentScene,
                    showCurrentScene = true,
                    showPosition = true,
                    showInfoButton = false,
                    onClickInfo,
                    onClickRanking
                }) => {

    const roomScores = useSelector((state) => state.logic.roomScores);
    const idAvatar = useSelector((state) => state.userReducer.selectedAvatar);
    const [position, setPosition] = useState("-");

    const avatar = AVATARS.find(item => item.id === idAvatar).avatar;

    const history = useHistory();
    const location = useLocation();

    const handleRankingClick = () => {
        if (location.pathname === "/jugador/ranking") return;

        console.log("clicked ranking", onClickRanking.toString());

        if (onClickRanking && onClickRanking() !== null) {
            //onClickRanking();
            console.log("1")
        } else {
            console.log("2")
            history.push("/jugador/ranking", {
                buttonTitle: "Volver",
                buttonAction: (() => history.goBack()).toString()
            })
        }
    }

    useEffect(() => {
        let i = 0;
        for (let team of roomScores) {
            if (team.isCurrent) {
                setPosition(i + 1);
                return;
            }
            i++;
        }
    }, [roomScores])

    return (
        <div>
            <div style={{display: "flex", flex: 1, justifyContent: "space-between", alignItems: "center", height: 73}}>
                <div style={{
                    backgroundColor: Colors.marigold,
                    transform: "skew(-40deg)",
                    width: 6,
                    height: 73,
                    boxShadow: `0px 0px 4px ${Colors.marigold}`,
                    marginLeft: 88
                }}/>
                <div style={{
                    backgroundColor: Colors.marigold,
                    transform: "skew(-40deg)",
                    width: 6,
                    height: 73,
                    boxShadow: `0px 0px 4px ${Colors.marigold}`,
                    marginLeft: 8,
                    marginRight: 10
                }}/>
                <div style={{position: "absolute", display: "flex", flex: 1, width: "100%", justifyContent: "center"}}>
                    <div style={{
                        backgroundColor: Colors.marigold,
                        transform: "skew(-40deg)",
                        width: 196,
                        height: 84,
                        marginLeft: 15,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        zIndex: 10
                    }}>
                        <span style={{transform: "skew(40deg)", marginLeft: -15}}><span
                            style={{fontSize: 32}}>{score}</span> <br/>PUNTOS</span>
                    </div>
                </div>
                <div style={{
                    background: "linear-gradient(180deg, #444444 0%, rgba(68, 68, 68, 0.37) 100%)",
                    display: "flex",
                    flex: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                    height: 73,
                    clipPath: "polygon(60px 0%,100% 0%,100% 100%,0% 100%)",
                    paddingLeft: 30,
                    marginLeft: -32
                }}>

                    <img src={logo} height={64}/>

                    <div style={{marginRight: 84, display: "flex"}}>
                        {showInfoButton &&
                        <HeaderButton onClick={onClickInfo} clickable><img src={infoDefault} style={{height: '100%'}}/></HeaderButton>}
                        <HeaderButton clickable onClick={handleRankingClick}><img src={rankingDefault}
                                                                                  style={{height: '100%'}}/></HeaderButton>
                        <HeaderButton>
                            <img src={avatar} style={{objectFit: 'contain', height: '100%', width: '100%'}}/>
                        </HeaderButton>
                    </div>
                </div>
            </div>
            <div style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                marginBottom: 22,
                marginLeft: 84,
                marginRight: 84
            }}>
                {showCurrentScene &&
                <span style={{marginTop: 10, opacity: 0.3, fontWeight: "bold"}}>ESCENARIO {currentScene}/6</span>}
                <div style={{flex: 1}}/>
                {showPosition && <div style={{
                    backgroundColor: "#2F2F2F", width: 176, borderBottomRightRadius: 9,
                    borderBottomLeftRadius: 9, display: "flex", flexDirection: "column", alignItems: "center",
                    justifyContent: "center", fontSize: 14
                }}>
                    <div style={{backgroundColor: Colors.marigold, height: 1, width: 'calc(100% - 24px)'}}/>
                    <span style={{padding: 6}}>Posición {position}/{roomScores.length}</span>
                </div>}
            </div>
        </div>
    )
}

export default Header;
