import axiosService from "../services/axios";

export const addNewScoreAPI = (escenario1 = null,
                               escenario2 = null,
                               escenario3 = null,
                               escenario4 = null,
                               escenario5 = null,
                               escenario6 = null) => {
    return axiosService.put(
        "/scores",
        {
            scenes: {
                escenario1,
                escenario2,
                escenario3,
                escenario4,
                escenario5,
                escenario6
            }
        }
    )
}

export const getScoresAPI = () => {
    return axiosService.get("/scores")
}

export const getScoreAPI = () => {
    return axiosService.get("/scores/own")
}
