export default {

    version: {
        position: "absolute",
        color: 'grey',
        margin: 0,
        top: 20, right: 0,
        fontSize: "12px",
        height: "auto",
    },

}
