import colors from '../../../theme/colors';

export default {
    tagContainer: {
        width: 134,
        height: 30,
        border: `1px solid ${colors.white}`,
        padding: 6,
        borderRadius: 4,
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'space-between'
    },
    tagText: {
        marginBlockEnd: 0,
        marginBlockStart: 0,
        textAlign: 'center',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        fontSize: 12,
        fontWeight: 400,
        maxWidth: 110,
        cursor: 'pointer'
    },
    closeIcon: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: colors.gainsboro,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    closeIconText: {
        marginBlockEnd: 0,
        marginBlockStart: 0,
        color: colors.erieBlack,
        fontSize: 9,
        cursor: 'pointer'
    }
}
