import React from 'react';

import DafoOption from "../../units/DafoOption";

import styles from "./styles";

const Dafo = ({product, onOptionSelected, optionsSelected, maximumReached}) => {
    return (
        <div style={styles.content}>
            <p style={styles.name}>{product.name}</p>
            <div style={styles.optionsLayout}>
                {product.options.map(option => {

                    const isOptionSelected = optionsSelected.find(optionSelected => optionSelected === option.id)

                    return <DafoOption key={option.id} name={option.name} id={option.id} onOptionSelected={onOptionSelected} isOptionSelected={!!isOptionSelected} maximumReached={maximumReached}/>
                })}
            </div>
        </div>
    )
};

export default Dafo;
