import axiosService from "../services/axios";

export const startGameAPI = () => {
    return axiosService.post("/game/start")
}

export const resetGameAPI = () => {
    return axiosService.post("/game/reset")
}
export const stopGameAPI = () => {
    return axiosService.post("/game/stop")
}

export const gameStatusAPI = () => {
    return axiosService.get("/game/status")
}
