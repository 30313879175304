import {logic} from "../../../data/scenes/logic";
import {
    COMPLETE_STAGE,
    QUESTION_GROUPS_TYPE,
    SAVE_SCENE_AND_TOTAL_SCORE,
    SAVE_USER_AVATAR,
    SET_GAME_STATUS,
    SET_ROOM_SCORES_STATUS
} from "../../actions/actionType";
import {STAGE_STATUS} from "../../../utils/constants";


const initialState = logic;

export const logicReducer = (state = initialState, action) => {
    switch (action.type) {
        case QUESTION_GROUPS_TYPE:
            console.log("state =>", state);
            console.log("action =>", action);
            let groupStateIndex = state[action.payload.currentPath].findIndex(item => {
                return item.id === action.payload.groupId
            });

            let newGroupState = {
                ...state[action.payload.currentPath][groupStateIndex],
                errors: action.payload.errors
            }

            let newArr = [...state[action.payload.currentPath]];
            newArr[groupStateIndex] = newGroupState

            return {
                ...state,
                [action.payload.currentPath]: newArr,
            }

        case SAVE_SCENE_AND_TOTAL_SCORE:
            return {
                ...state,
                totalScore: state.totalScore + action.payload.score,
                scenesScores: {
                    ...state.scenesScores,
                    [action.payload.scene]: state.scenesScores[action.payload.scene] + action.payload.score
                }
            }
        case SET_GAME_STATUS:
            return {
                ...state,
                gameHasStarted: action.payload.gameHasStarted !== undefined ? action.payload.gameHasStarted : state.gameHasStarted,
                gameActive: action.payload.gameActive !== undefined ? action.payload.gameActive : state.gameActive,
                gameEndTime: action.payload.gameEndTime !== undefined ? action.payload.gameEndTime : state.gameEndTime
            }
        case SET_ROOM_SCORES_STATUS:
            return {
                ...state,
                roomScores: action.payload
            }

        case COMPLETE_STAGE:
            let currentActiveStageIndex = state.stagesStatus.findIndex(stage => stage.status === STAGE_STATUS.CURRENT)
            let newStagesStatus = [...state.stagesStatus];
            newStagesStatus[currentActiveStageIndex].status = STAGE_STATUS.COMPLETED;

            if (currentActiveStageIndex < state.stagesStatus.length) {
                if (newStagesStatus[currentActiveStageIndex + 1])
                    newStagesStatus[currentActiveStageIndex + 1].status = STAGE_STATUS.CURRENT;
            }
            return {
                ...state,
                stagesStatus: newStagesStatus
            }
        case SAVE_USER_AVATAR:
            return {
                ...initialState
            }
        default:
            return state
    }
}
