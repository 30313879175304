import styled from "styled-components";
import {Button as MuiButton} from "@material-ui/core"

export const OptionSelect = styled(MuiButton)`
  padding: 8px;
  background: var(--sheenGreen);
  border: 2px solid var(--sheenGreen);
  min-height: 48px;
  min-width: 150px;
  border-radius: 0;
  color: var(--white) !important;
  font-size: 16px;
  transform: skew(-40deg);
  font-family: Aller;

  span {
    transform: skew(23deg);
  }

  :hover {
    background: var(--sheenGreen);
  }
`
