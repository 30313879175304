import React from 'react';

import colors from '../../../theme/colors'
import styles from "./styles";

const TableInteractionsTag = ({tagText, onRemoveClick, isWrong}) => {

    const strip = (html) => {
        let tmp = document.implementation.createHTMLDocument("New").body;
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    return(
        <div style={{...styles.tagContainer, backgroundColor: isWrong ? colors.tenneTawny : colors.erieBlack}}>
            <p style={styles.tagText}>
                {strip(tagText)}
            </p>
            <div style={styles.closeIcon} onClick={onRemoveClick}>
                <p style={styles.closeIconText}>x</p>
            </div>
        </div>
    )
};

export default TableInteractionsTag;
