import QuestionGroups from "../../../components/composed/QuestionGroups";
import {useHistory, useParams} from "react-router-dom";
import {content} from "../../../data/scenes/content";
import {logic} from "../../../data/scenes/logic";
import Body from "../../../components/composed/Body/Body";
import React, {useState} from "react";
import Modal from "../../../components/composed/Modal/Modal";
import Colors from "../../../theme/colors";
import {completeStage} from "../../../store/actions/stagesType";
import {useDispatch} from "react-redux";
import styles from "../stage6/styles";

export const Stage3 = () => {

    const [initialModal, setInitialModal] = useState(true);
    const [infoModal, setInfoModal] = useState(false);
    const [endModal, setEndModal] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const escenario = 3;
    const {groupId} = useParams();
    const currentPath = `/jugador/escenario${escenario}`;
    let groupContent = content[currentPath].find(item => item.id === groupId);
    let groupLogic = logic[currentPath].find(item => item.id === groupId);
    let href = window.location.href;

    const handleFinishState = () => {
        if (groupContent.lastScreenStage) {
            dispatch(completeStage());
            setEndModal(true);
        } else history.push(groupContent.nextScreen)
    }

    const showIndexOfQuestion = () => {
        const totalOfQuestions = content[currentPath].length;

        return (
            <div style={styles.questionIndex}>
                <p style={styles.questionIndexText}>{content[currentPath].findIndex(item => item.id === groupId) + 1}</p>
                <p style={styles.questionIndexText}> / </p>
                <p style={styles.questionIndexText}>{totalOfQuestions}</p>
            </div>
        )
    };

    return (
        <Body onClickInfo={() => setInfoModal(true)} showInfoButton={true}>
            <div style={{overflowY: "auto", width: '100%'}}>
                {showIndexOfQuestion()}
                <QuestionGroups escenario={escenario} groupId={groupId} currentPath={currentPath}
                                groupContent={groupContent} handleFinishStage={handleFinishState}
                                groupLogic={groupLogic} href={href} showNumErrors={false}/>
            </div>

            <Modal title={"Escenario 3: Visto para sentencia"} open={initialModal} actions={[{
                title: "CONTINUAR", onClick: () => {
                    setInitialModal(false);
                    setInfoModal(true)
                }
            }]}>
                <div>
                    <h4>Parece ser que este hombre necesita que le ayudemos a aclarar algunos temas, vamos a ello.</h4>
                </div>
            </Modal>
            <Modal title={"Instrucciones"} open={infoModal}
                   actions={[{title: "Aceptar", onClick: () => setInfoModal(false)}]}>
                <h4 style={{marginTop: 0}}>A continuación, se mostrarán todas las afirmaciones que se habían formulado
                    incorrectamente en el escenario anterior.</h4>
                <h4>Cada afirmación tiene una sola justificación correcta.</h4>
                <h4>De un grupo de cuatro opciones, seleccionad la opción que consideréis adecuada y clicad en
                    “siguiente”.</h4>
                <h4>
                    El sistema os confirmará si habéis acertado o no. Si habéis seleccionado una opción incorrecta, se os penalizará y tendréis que responder la afirmación de nuevo.
                </h4>
                <h4>Cuando hayáis contestado todas las afirmaciones correctamente, podréis avanzar hacia el siguiente
                    escenario.</h4>

                <ul>
                    <li><strong>Objetivo:</strong> seleccionar la justificación adecuada para cada afirmación mostrada.
                    </li>
                    <li><strong>Penalizaciones:</strong> envío de respuestas incorrectas.</li>
                    <li><strong>Puntuación:</strong>
                        <ul>
                            <li>Respuesta correcta &rarr; +20 puntos</li>
                            <li>Respuesta incorrecta &rarr; -10 puntos</li>
                        </ul>
                    </li>
                </ul>

                <h4>Recordad que disponéis de comodines en caso de necesitar ayuda.</h4>

            </Modal>
            <Modal title={"¡Bien hecho!"} open={endModal} actions={[{
                title: "CONTINUAR", onClick: () => {
                    setEndModal(false);
                    history.push(groupContent.nextScreen, {
                        buttonTitle: "Continuar",
                        buttonActionPath: "/jugador/selector-escenarios"
                    })
                }
            }]}>
                <div style={{
                    backgroundColor: Colors.artichoke,
                    padding: 12,
                    borderRadius: 6,
                    marginBottom: 8,
                    maxWidth: '80%'
                }}>
                    <span style={{fontWeight: "bold"}}>Técnico</span>
                    <p>¡Fantástico!, muchas gracias por vuestra ayuda. Además, mirad, parece que ya ha llegado la
                        directora.</p>
                </div>

            </Modal>
        </Body>
    )
}

export default Stage3;
