export default {
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflowY: 'auto',
        maxWidth: '100%'
    },
    tableContent: {
        backgroundColor: 'rgba(0, 0, 0, 0.45)',
        borderRadius: 16,
        padding: '42px 84px 20px 84px',
        marginBottom: 16,
        maxWidth: '100%',
        boxSizing: 'border-box'
    },
    title: {
        fontSize: 42,
        marginBlockStart: 0
    }
}
